






















import modal from '@/components/plannerComponent/components/modal/modal';
export default modal;
