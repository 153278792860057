import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Calendar, Units } from 'dayspan';
import { IEvents } from '@/services/program/plannerService';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import Events from '../../components/Events/Events.vue';
import plannerStore from '@/store/modules/plannerStore';
@Component({
  name: 'calender',
  components: {
    'events': Events
  }
})
export default class CalenderEventItem extends Vue {
  storeKey: string = 'dayspanState2';
  calendar = Calendar.months();
  readOnly = true;
  defaultEvents: IEvents[] = [];
  defaultWeekHoliday: IEvents[] = [];
  mountedref = false;
  customDate = '';
  timer: any;
  touchduration = 500;
  elementId = '';
  $refs!: {
    cal2: HTMLFormElement;
  };
  @Prop()
  events!: [];

  @Prop()
  editEventPayload!: {};
  @Prop()
  isSingleEventDownloading!: boolean;

  @Prop()
  eventMode!: string;
  @Prop()
  isMobileView!: boolean;
  @Prop()
  userRoleAccessToButton!: Array<number>;
  @Prop()
  userRoleId!: number;

  @Prop()
  toggleListView!: boolean;

  @Prop()
  typeOfView!: string;

  get view() {
    return plannerStore.getDurationView;
  }

  mounted() {
    const view: any = this.view === 'weeks'
      ? 'weeks'
      : 'months';
    this.changeView(view);
    if (this.events.length > 0) {
      this.calenderStructure();
      this.calenderStructureForWeek();
    }
    else {
      this.createTitleDate();
    }
    this.mountedref = true;
  }

  @Watch('events', { deep: true })
  updateView() {
    if (this.mountedref) {
      this.calenderStructure();
      this.calenderStructureForWeek();
      this.loadState();
    }
  }

  calenderStructure() {
    const tempEventList = JSON.parse(JSON.stringify(this.events));
    const list: IEvents[] = [];
    tempEventList.forEach((item: any) => {
      if (!item.isEvent) {
        const differenceinTime: number = new Date(item.startDate).getTime() - new Date(item.endDate).getTime();
        const differenceinDays: number = differenceinTime / (1000 * 3600 * 24);
        if ((differenceinDays >= 1) || (differenceinDays <= -1)) {
          list.push({
            data: { title: item.name, color: APP_CONST.STYLE.COLOR.NEUTRAL_900, class: 'ltStrip', elementId: `holiday_l_${item.id}`, id: item.id },
            schedule: {
              month: [new Date(item.startDate).getMonth()], duration: 30,
              durationUnit: 'minutes', times: ['00'], year: [new Date(item.startDate).getFullYear()], dayOfMonth: [new Date(item.startDate).getDate()]
            }
          });
          list.push({
            data: { title: item.name, color: APP_CONST.STYLE.COLOR.NEUTRAL_900, class: 'rtStrip', elementId: `holiday_r_${item.id}`, id: item.id },
            schedule: {
              month: [new Date(item.endDate).getMonth()], duration: 30,
              durationUnit: 'minutes', times: ['00'], year: [new Date(item.endDate).getFullYear()], dayOfMonth: [new Date(item.endDate).getDate()]
            }
          });
        }
        else {
          list.push({
            data: { title: item.name, color: APP_CONST.STYLE.COLOR.NEUTRAL_900, class: 'ltStrip rtStrip', elementId: `holiday_${item.id}`, id: item.id },
            schedule: {
              month: [new Date(item.startDate).getMonth()], duration: 30,
              durationUnit: 'minutes', times: ['00'], year: [new Date(item.startDate).getFullYear()], dayOfMonth: [new Date(item.startDate).getDate()]
            }
          });
        }
      }
      else {
        list.push({
          data: { title: item.name, color: APP_CONST.STYLE.COLOR.NEUTRAL_900, class: '', elementId: `event_${item.id}`, id: item.id, timeDuration: `${item.event_start_time.toLowerCase()} - ${item.event_end_time.toLowerCase()}` },
          schedule: {
            month: [new Date(item.startDate).getMonth()], times: [item.event_start_time_24], duration: item.event_duration,
            durationUnit: 'minutes', dayOfMonth: [new Date(item.startDate).getDate()], year: [new Date(item.startDate).getFullYear()]
          }
        });
      }
    });
    this.defaultEvents = JSON.parse(JSON.stringify(list));
  }

  calenderStructureForWeek() {
    const tempWeekEventList = JSON.parse(JSON.stringify(this.events));
    const list: IEvents[] = [];
    tempWeekEventList.forEach((item: any, index: number) => {
      item.event_duration = item.isEvent
        ? item.event_duration
        : `${item.event_duration + 1440}`;
      list.push({
        data: { title: item.name, color: APP_CONST.STYLE.COLOR.NEUTRAL_900, class: item.isEvent
          ? ''
          : 'ltstrip', elementId: `event_${item.id}`, id: item.id, timeDuration: `${item.event_start_time.toLowerCase()} - ${item.event_end_time.toLowerCase()}` },
        schedule: {
          month: [new Date(item.startDate).getMonth()], times: [item.event_start_time_24], duration: item.event_duration,
          durationUnit: 'minutes', dayOfMonth: [new Date(item.startDate).getDate()], year: [new Date(item.startDate).getFullYear()]
        }
      });
    });
    this.defaultWeekHoliday = JSON.parse(JSON.stringify(list));
  }

  refreshCalendar() {
    if (this.mountedref) {
      this.calenderStructure();
      this.calenderStructureForWeek();
      this.loadState();
      this.$forceUpdate();
    }
  }

  loadState() {
    const state: { preferToday: boolean; events: IEvents[]; eventsOutside: boolean; updateRows: boolean; fill: boolean; listTimes: boolean; updateColumns: boolean } = { preferToday: false, events: [], eventsOutside: true, updateRows: true, fill: true, listTimes: false, updateColumns: false };
    if (!state.events || !state.events.length) {
      state.events = this.view == 'months'
        ? this.defaultEvents
        : this.defaultWeekHoliday;
      state.fill = this.view == 'months'
        ? true
        : false;
      state.listTimes = this.view == 'months'
        ? false
        : true;
      state.updateColumns = this.view == 'months'
        ? false
        : true;

    }
    this.$refs.cal2 && this.$refs.cal2.setState(state);
    this.createTitleDate();
  }

  changeView(view: 'weeks' | 'months') {
    this.mountedref = true;
    plannerStore.changeDurationView(view);

    const startMonthIndex = this.calendar.start.month;
    const around = startMonthIndex !== new Date().getMonth()
      ? ((view === 'months')
        ? this.calendar.end
        : this.calendar.start)
      : undefined;
    this.calendar = Calendar[view]();
    const type: 'WEEK' | 'MONTH' = view === 'weeks'
      ? 'WEEK'
      : 'MONTH';
    this.calendar = Calendar.forType(Units[type], 1, around);
    setTimeout(() => {
      this.loadState();
      this.$emit('calenderView', view, this.customDate);
    });
  }

  prev() {
    this.calendar.prev(1);
    this.createTitleDate();
    this.$emit('calenderView', this.view, this.customDate);
  }

  next() {
    this.calendar.next(1);
    this.createTitleDate();
    this.$emit('calenderView', this.view, this.customDate);
  }

  createTitleDate() {
    const inputcal = this.calendar;
    const endMonthIndex = inputcal && inputcal.end.month;
    const startMonthIndex = inputcal && inputcal.start.month;
    const startyear = inputcal && inputcal.start.year;
    const endyear = inputcal && inputcal.end.year;
    const endDayOfMonth = inputcal && inputcal.end.dayOfMonth;
    const startDayOfMonth = inputcal && inputcal.start.dayOfMonth;
    if (endDayOfMonth - startDayOfMonth > 25) {
      this.customDate = APP_CONST.MONTH_NAME[endMonthIndex] + ' ' + endyear;
    }
    else {
      if (document.documentElement.clientWidth < 375) {
        this.customDate = APP_CONST.MONTH_NAME[startMonthIndex].substring(0, 3);
      }
      else if (document.documentElement.clientWidth >= 375 && document.documentElement.clientWidth < 769 && APP_UTILITIES.checkMobile()) {
        if (startMonthIndex == endMonthIndex) {
          this.customDate = APP_CONST.MONTH_NAME[startMonthIndex].substring(0, 3) + ' ' + startDayOfMonth + '-' + endDayOfMonth;
        }
        else {
          this.customDate = APP_CONST.MONTH_NAME[startMonthIndex].substring(0, 3) + ' ' + startDayOfMonth + '-' + APP_CONST.MONTH_NAME[endMonthIndex].substring(0, 3) + ' ' + endDayOfMonth;
        }
      }
      else {
        if ((startyear == endyear) && ((startMonthIndex != endMonthIndex))) {
          this.customDate = APP_CONST.MONTH_NAME[startMonthIndex] + ' ' + startDayOfMonth + ' - ' + APP_CONST.MONTH_NAME[endMonthIndex] + ' ' + endDayOfMonth + ', ' + endyear;
        }
        else if ((startMonthIndex == endMonthIndex)) {
          this.customDate = APP_CONST.MONTH_NAME[startMonthIndex] + ' ' + startDayOfMonth + ' - ' + endDayOfMonth + ', ' + endyear;
        }
        else {
          this.customDate = APP_CONST.MONTH_NAME[startMonthIndex] + ' ' + startDayOfMonth + ', ' + startyear + '-' + APP_CONST.MONTH_NAME[endMonthIndex] + ' ' + endDayOfMonth + ', ' + endyear;

        }
      }
    }
  }

  callHover($event: any) {
    let hoverPosition = { top: '0px', left: '0px' };
    const boundBox = $event.getBoundingClientRect();
    const coordX = boundBox.left;
    const coordY = boundBox.top;
    hoverPosition = {
      top: (coordY + 40).toString() + 'px',
      left: (coordX + 5).toString() + 'px'
    };
    return hoverPosition;
  }

  tooltip(elemId: string, title: string) {
    this.elementId = `tooltip_${elemId}`;
    const checkElementExistence = document.getElementById(this.elementId);
    if (!checkElementExistence) {
      const element = document.getElementById(elemId);
      const elemToAppendTooltip = element && element.parentElement && element.parentElement.parentElement;
      const cords = this.callHover(elemToAppendTooltip);
      const node = document.createElement('span');
      node.id = `tooltip_${elemId}`;
      node.setAttribute('style', `top:${cords.top};left:${cords.left}`);
      node.setAttribute('class', 'tooltip-new');
      const textnode = document.createTextNode(`${title}`);
      node.appendChild(textnode);
      const elementdaySpan = document.getElementById('eventspan');
      elementdaySpan && (elementdaySpan.append(node));
    }
  }

  removeTooltip(elemId: string) {
    const tooltipElem = document.getElementById(elemId);
    tooltipElem && (tooltipElem.remove());
  }

  touchStart($event: any, elementId: any, title: any) {
    this.timer = setTimeout(() => {
      this.tooltip(elementId, title);
    }, 1500);
  }
  touchend($event: any, elementId: any, title: any) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  created() {
    document.addEventListener('scroll', () => {
      this.removeTooltip(this.elementId);
    });
  }
  beforeDestroy() {
    // Detach the listener when the component is gone
    document.removeEventListener('scroll', () => {
      this.removeTooltip(this.elementId);
    });
    plannerStore.changeDurationView('months');
  }

  editEvent() {

    this.$emit('editEvent');
  }

  eventData(ev: any) {
    this.$emit('eventData', ev);
    this.refreshCalendar();
  }

  emitMode(arg: string) {
    this.$emit('emitMode', arg);
  }
  eventPrintView(obj: object) {
    this.$emit('emitEventPrintView', obj);
  }

  createNewInstance(data: any) {
    this.eventMode = 'View';
    return JSON.parse(JSON.stringify(data));
  }
  downloadSingleEvent(e: any, plannerEventObj: object) {
    e.preventDefault();
    this.$emit('downloadSingleEvent', e, plannerEventObj);
  }

  openDrawer(event: any) {
    event.handled = true;
    setTimeout(() => {
      const htmlElem = <any>document.getElementsByTagName('html')[0];
      htmlElem.classList.add('overflow-page');
    }, 300);
  }
}