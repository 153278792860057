import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from '@/constants/AppConst';
import plannerStore from '@/store/modules/plannerStore';

@Component({})
export default class PlannerPrintComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public roleId: number = 0;
    public getTasksMenuList: any = JSON.parse(JSON.stringify(APP_CONST.GET_TASK_MENUS));
    public programName: string = '';
    public getEventMenuList: any = JSON.parse(JSON.stringify(APP_CONST.GET_EVENT_MENUS));




    @Prop()
    toggleListView!: any;

    @Prop()
    isDownloadEventListViewInProgress!:boolean;

    @Prop()
    activeTab!: any;

    @Prop()
    startDate!: any;

    @Prop()
    endDate!: any;

    @Prop()
    siteId!: number;


    @Prop({ default: '' })
    selectedSiteName!: string;

    @Prop()
    siteValue!: string;



    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    formatDate(date: string) {
      return date
        ? APP_UTILITIES.formatDate(date, false, true)
        : '';
    }


    created() {
      this.programName = APP_UTILITIES.getCookie('progName') || '';
    }

    mounted() {
      const { roleId } = APP_UTILITIES.coreids();
      this.roleId = roleId;

    }

    updated() {
      this.programName = APP_UTILITIES.getCookie('progName') || '';
    }

    get isDownloadEvent(){
      return plannerStore.downloadEventState;
    }

}