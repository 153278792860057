import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import { VueEditor } from 'vue2-editor';
import APP_CONST from '@/constants/AppConst';
import API_CONST from '@/constants/ApiConst';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import APP_UTILITIES from '@/utilities/commonFunctions';
import UIkit from 'uikit';
import { deleteEvent, deleteholidayEvent, editHolidays, uploadEventFile, uploadHolidayFile, copyEvent } from '@/services/program/plannerService';
import { createHolidays } from '@/services/attedance/attedanceService';
import programPlanner from '@/store/modules/plannerStore';
import plannerStore from '@/store/modules/plannerStore';
import programConfigure from '@/store/modules/programConfigure';
import { IAddSiteDTO } from '@/services/program/adminService';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import { ISite } from '../../../../Model/model';

@Component({
  components: {
    'datepicker': DatepickerComponent,
    'notes-editor': VueEditor,
    'progress-button': ProgressButton,
    'multi-select-dropdown': MultiSelectDropdown
  }
})

export default class Events extends Vue {

  @Prop()
  eventMode!: string;

  @Prop()
  editEventPayload!: {};
  @Prop()
  isMobileView!: boolean;
  @Prop()
  userRoleAccessToButton!: Array<number>;
  @Prop()
  userRoleId!: number;

  @Prop()
  details!: any;

  @Prop()
  isSingleEventDownloading!: boolean;


  @Prop()
  toggleListView!: boolean;

  @Prop()
  viewEventId!:number;

  @Prop()
  idOfEvent!:number;

  @Prop()
  siteDataResponse!:any;

  @Prop()
  typeOfView!:string;

  private objScreenText: ScreenText = new ScreenText();
  public sessionAdminId = APP_CONST.ROLE_TYPE_SESSION_ADMIN;
  public programData:any=[];
  public allSelected:boolean=false;
  public siteNameData:Array<string>=[];
  public siteNames : Array<string> = []; 
  public hoverStyleObj : any = {};
  public timeList: any = [];
  public toggleDropDownStartTimeMobile: boolean = false;
  public toggleDropDownStarttime: boolean = false;
  public toggleDropDownEndTime: boolean = false;
  public toggleDropDownEndTimeForMobile: boolean = false;
  public siteName : any = '';
  startTimeBlurr: boolean = false;
  endTimeBlurr: boolean = false;
  errorTimeMisMatch: boolean = false;
  timeError: boolean = false;
  fileSizeError: boolean = false;
  fileTypeError: boolean = false;
  maxFileError: boolean = false;
  validatedFields: Array<string> = ['name', 'date', 'startTime', 'endTime'];
  errorIndicator: boolean = false;
  public currentRoleId: any = 0;
  public userRoles: any = [];
  acceptFile = 'image/gif, image/jpeg, image/png,image/jpg,.pdf,.csv,.txt,text/plain,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation';
  validatedHolidayFields: Array<string> = ['name', 'startDate', 'endDate'];
  public dateType: string = '';
  public dateResponseError: boolean = APP_CONST.FALSE;
  public dateResponse: string = '';
  public allFieldRequired = APP_CONST.FALSE;
  private deleteConPopup: boolean = false;
  hoverText: string = '';
  hovering: boolean = false;
  hoverIndex: number = -1;
  onSubmit: boolean = false;
  public viewPopup: boolean = false;
  public viewInfoPopup: boolean = false;
  public pointerEventNone:boolean = true;
  public customToolbar: any = APP_CONST.CUSTOM_TOOLBAR;
  public showSeeMoreButton: boolean = false;
  public showSeeLessButton: boolean = false;
  public apiResponseCode: number = 0;
  public isValid: boolean = true;
  public disableCopyBtn: boolean = false;
  public closeUndoPopup: boolean = false;
  public copiedEventId: number = -1;
  public copyEventRevert: boolean = false;
  allSites : IAddSiteDTO[] = [] as IAddSiteDTO[];
  public siteDrop:boolean = false;
  public sitesNameList : Array<string> = [];
  public addedSiteList : Array<string> = [];
  public serverPayload: any = {
    'Name': '',
    'Date': '',
    'ProgramId': 0,
    'SiteId': 0,
    'StartTime': '',
    'EndTime': '',
    'Location': '',
    'Notes': '',
    'FileIds': [],
    'siteIds':[],
    'isEdit':true
  };
  fileArray: any = [];
  public isChecked:boolean=false;
  public plannerEventObj: any = {
    id: 0,
    programId: 0,
    siteId: 0,
    siteIds :[],
    name: '',
    location: '',
    date: '',
    startDate: '',
    endDate: '',
    startTime: '7:00 AM',
    endTime: '7:30 AM',
    notes: '',
    fileIds: [],
    isEvent: true,
    isEdit:true
  };
  count :number =0;
  errorObj: any = {
    'name': {
      isError: false,

    },
    'date': {
      isError: false,

    },
    'location': {
      isError: false,

    },
    'startTime': {
      isError: false,
      isTimeFormat: false,
      isTimeMissMatch: false
    },
    'endTime': {
      isError: false,
      isTimeFormat: false,
      isTimeMissMatch: false

    },
    'startDate': {
      isError: false

    },
    'endDate': {
      isError: false,
    }
  };
  public selectedSites: Array<number> = [];
  public dropdownSiteData: any = [];
  public multiSelectSitesDropdown = {label: 'Select Site/Session', id: 0, value: 'Select', required: false, disable: false, search : false, placeholder : 'Search', dropdownList: [] as ISite[], checked:false, isAllSelect:false, selectDropdownCount:true};

  get events() {
    return plannerStore.eventsList;
  }

  get eventsFields(){
    return plannerStore.getEventSortingFields;
  }

  get sitesLists(){
    return plannerStore.sitesList;
  }

  /* istanbul ignore next */
  get multiSelectSiteData(){
    return plannerStore.getMultiSelectSitesDropdown;
  }

  @Watch('siteDataResponse', { deep: true })
  getSiteDataResponse(val:any){
    this.getallSitesData(val);
  }

  selectionRecords(sites:any){
    this.selectedSites = [];
    sites.dropdownList.forEach((site:any) => {
      this.programData.forEach((program:any) => {
        if(program.siteId==site.id){
          program.isChecked = site.checked;
        }
      });
    });
  }

  get siteDropdownData(){
    const checkDropdownArray: Array<any> = [];
    this.programData.forEach((data: { siteId: any; siteName: any; isChecked: any })=>{
      checkDropdownArray.push({id:data.siteId, value:data.siteName, checked:data.isChecked});
    });
    this.dropdownSiteData =  checkDropdownArray;
    this.multiSelectSitesDropdown.dropdownList = this.dropdownSiteData;
    return this.multiSelectSitesDropdown;
  }

  getallSitesData(val:any){
    this.programData = val;
    this.programData.forEach((program : any)=>{
      this.siteNameData.push(program.siteName);
    });
  }

  @Watch('details')
  checkdeatils(details: any) {
    this.count = this.count+1;
    const name:any = `overlay-${details.elementId}`;
    const openmodal: any = document.getElementById(name);
    if (details && details.id > 0 && this.events.length > 0 && this.count <= 2) {
      const tempEventList = this.events.find((obj: any) => obj.id == details.id);
      this.eventToEdit(tempEventList);
      this.$emit('eventPrintData', tempEventList);

    }
  }

  @Watch('plannerEventObj', { deep: true })
  checkHeight() {
    if (this.toggleListView) {
      setTimeout(() => {
        this.calculateNotesHeight();
      }, 100);
    }
    else {
      setTimeout(() => {
        this.calculateNotesHeight();
      }, 100);
    }
  }

  calculateNotesHeight() {
    const ele = this.toggleListView || this.eventMode == 'add'
      ? document.getElementById('text-shown-event')
      : document.getElementById(`text-shown-event${this.details.elementId}`);
    const eleHeight = ele && ele.offsetHeight;
    if (this.isMobileView && eleHeight && (eleHeight >= 178)) {
      this.showSeeMoreButton = true;
    }
    else if (eleHeight && (eleHeight >= 248)) {
      this.showSeeMoreButton = true;
    }
  }

  showMore() {
    this.showSeeMoreButton = false;
    this.showSeeLessButton = true;
  }

  showLess() {
    this.showSeeLessButton = false;
    this.showSeeMoreButton = true;
  }

  @Watch('editEventPayload', { deep: true, immediate: true })
  eventPayload(val: any) {
    this.eventToEdit(val);
  }

  eventToEdit(val: any) {
    if (val && Object.keys(val).length > 0) {
      this.plannerEventObj.isEdit = val.isEdit;
      this.plannerEventObj.name = val.name;
      this.plannerEventObj.id = val.id;
      this.plannerEventObj.siteIds = val.siteIds;
      this.plannerEventObj.location = val.location;
      this.plannerEventObj.endTime = val.event_end_time;
      this.plannerEventObj.startTime = val.event_start_time;
      this.plannerEventObj.notes = val.notes;
      this.plannerEventObj.date = APP_UTILITIES.formatDate(val.startDate, false, false);
      this.plannerEventObj.isEvent = val.isEvent;
      this.plannerEventObj.startDate = APP_UTILITIES.formatDate(val.startDate, false, false);
      this.plannerEventObj.endDate = APP_UTILITIES.formatDate(val.endDate, false, false);
      const checkDropdownArrayEdit: { id: any; value: any; checked: any }[] =[];
      this.programData.forEach((data:any)=>{
        this.plannerEventObj.siteIds.forEach((siteId:any) => {
          if(data.siteId == siteId){
            data.isChecked = true;
            checkDropdownArrayEdit.push({id:data.siteId, value:data.siteName, checked:data.isChecked});
          }
        });
      });
      this.multiSelectSitesDropdown.dropdownList = this.dropdownSiteData;
      const addEventMultiselect = this.$refs && this.$refs.addEventMultiselect as any;
      if(addEventMultiselect){
        addEventMultiselect.halfSelection();
      }
      this.plannerEventObj.fileIds = val.documentDtos
        ? val.documentDtos.map((obj: any) => {
          return obj.id; 
        })
        : [];
      this.fileArray = val.documentDtos
        ? val.documentDtos.map((obj: any) => {
          return { id: obj.id, isEvent: val.isEvent, file: JSON.parse(JSON.stringify(obj).split('"fileName":').join('"name":')) };
        })
        : [];
    }
    this.programData.forEach((program:any) => {
      for(let i=0 ; i<= this.plannerEventObj.siteIds.length ; i++){
        if(program.siteId == this.plannerEventObj.siteIds[i]){
          program.isChecked = true;
          this.addedSiteList.push(program.siteName);
        }
      }
      
    });
    this.$emit('addedSiteList', this.addedSiteList);
    this.programData = JSON.parse(JSON.stringify(this.programData));
    if(this.eventMode == 'View') {
      if(val !== {}){
        let programData : any = [];
        this.siteNames = [];
        programData = this.sitesLists;
        programData.forEach((program : any)=>{
          for(let i=0 ; i<= this.plannerEventObj.siteIds.length ; i++){
            if(program.siteId == this.plannerEventObj.siteIds[i]){
              this.siteNames.push(program.siteName);
            }
          }
        });
      }
    }
  }
  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  toggleDrop(type: string, close?:boolean) {
    if (type == 'startTime') {
      /* istanbul ignore next */
      if(this.toggleDropDownEndTime || this.toggleDropDownEndTimeForMobile){
        this.toggleDropDownEndTimeForMobile =false;
        this.toggleDropDownEndTime = false;
      }
      this.toggleDropDownStartTimeMobile = this.toggleDropDownStartTimeMobile
        ? APP_CONST.FALSE
        : (close
          ? APP_CONST.FALSE
          : APP_CONST.TRUE);
      this.toggleDropDownStarttime = this.toggleDropDownStarttime
        ? APP_CONST.FALSE
        : (close
          ? APP_CONST.FALSE
          : APP_CONST.TRUE);

    }
    else {
      /* istanbul ignore next */
      if(this.toggleDropDownStarttime || this.toggleDropDownStartTimeMobile){
        this.toggleDropDownStartTimeMobile =false;
        this.toggleDropDownStarttime = false;
      }
      this.toggleDropDownEndTime = this.toggleDropDownEndTime
        ? APP_CONST.FALSE
        : (close
          ? APP_CONST.FALSE
          : APP_CONST.TRUE);
      this.toggleDropDownEndTimeForMobile = this.toggleDropDownEndTimeForMobile
        ? APP_CONST.FALSE
        : (close
          ? APP_CONST.FALSE
          : APP_CONST.TRUE);
    }
    this.timeAutoscroll();
  }
  dropDownHandler(type: string, time: string) {
    if (type == 'startTime') {
      this.errorObj.startTime.isTimeFormat = false;
      this.plannerEventObj.startTime = time;
      const element: any = document.getElementById('startTime');
      if(element){
        element.innerHTML = this.plannerEventObj.startTime;
      }
      this.plannerEventObj.endTime = this.validateTime(this.plannerEventObj.startTime, this.plannerEventObj.endTime)
        ? this.add30Min(APP_UTILITIES.convertTime12to24(time))
        : this.plannerEventObj.endTime;
    }
    else {
      this.errorObj.endTime.isTimeFormat = false;
      this.plannerEventObj.endTime = time.trim();
      const element: any = document.getElementById('endTime');
      if (element) {
        element.innerHTML = this.plannerEventObj.endTime; 
      }
      this.plannerEventObj.startTime = this.validateTime(this.plannerEventObj.startTime, this.plannerEventObj.endTime)
        ? this.sub30Min(APP_UTILITIES.convertTime12to24(time))
        : this.plannerEventObj.startTime;

    }
    this.timeAutoscroll();
  }
  timeAutoscroll() {
    setTimeout(() => {
      let starttimeElement: any;
      let endtimeElement: any;
      if (this.isMobileView) {
        starttimeElement = document.getElementById(this.plannerEventObj.startTime + 'startTimeMobile');
        endtimeElement = document.getElementById(this.plannerEventObj.endTime + 'endTimeMobile');
      }
      else {
        starttimeElement = document.getElementById(this.plannerEventObj.startTime + 'startTime');
        endtimeElement = document.getElementById(this.plannerEventObj.endTime + 'endTime');
      }
      if (starttimeElement) {
        starttimeElement.parentNode.scrollTop = starttimeElement.offsetTop;
      }
      if (endtimeElement) {
        endtimeElement.parentNode.scrollTop = endtimeElement.offsetTop;

      }
    }, 10);

  }
  addEvent() {
    this.getProgramId();
    this.getSiteId();
    this.apiResponseCode=0;
    if (!this.plannerEventObj.isEvent) {
      this.onSubmit = true;
      this.saveholidayEvent();
    }
    else {
      this.saveEvent();
    }
  }

  mergeSiteId(){
    if(this.eventMode == 'Edit'){
      this.plannerEventObj.siteIds = [];
    }
    this.programData.forEach((program:any) => {
      if(program.isChecked ){
        this.plannerEventObj.siteIds.push(program.siteId);
      }
    });
  }

  saveEvent() {
    this.resetErrorObj();
    if (this.validateData() && !this.errorObj.startTime.isTimeFormat && !this.errorObj.endTime.isTimeFormat) {
      this.errorIndicator = false;
      const checkTimeValidation = APP_UTILITIES.compareTwoTimes(this.plannerEventObj.date + ' ' + APP_UTILITIES.convertTime12to24(this.plannerEventObj.startTime), this.plannerEventObj.date + ' ' + APP_UTILITIES.convertTime12to24(this.plannerEventObj.endTime));
      this.mergeSiteId();
      if (!checkTimeValidation.negativeTimeError && !checkTimeValidation.timeError) {

        this.errorTimeMisMatch = checkTimeValidation.negativeTimeError;
        this.timeError = checkTimeValidation.timeError;
        this.callApi();

      }
      else {
        this.errorTimeMisMatch = checkTimeValidation.negativeTimeError;
        this.timeError = checkTimeValidation.timeError;
      }
    }


  }

 
  async saveholidayEvent() {
    if (this.validateData()) {
      this.mergeSiteId();
      delete this.plannerEventObj.startTime;
      delete this.plannerEventObj.endTime;
      delete this.plannerEventObj.location;
      if (this.eventMode != 'Edit') {
        this.plannerEventObj.fileIds = this.getFileIds(this.fileArray);
        this.isValid = false;
        const response = await createHolidays(this.plannerEventObj);
        if (response.status == APP_CONST.RESPONSE_200) {
          this.allSelected=false;
          this.clearSelectedCheckBox();
          this.resetDropdown();
          this.apiResponseCode = APP_CONST.RESPONSE_200;
          this.plannerEventObj.isEvent = false;
          this.plannerEventObj.isEdit = true;
          this.plannerEventObj.date = this.plannerEventObj.startDate;
          this.plannerEventObj.id = response.data.holidayId;
          const ObjPlannerEvent = JSON.parse(JSON.stringify({ ...this.plannerEventObj, documentDtos: this.createFileArrayForView(this.fileArray), state: 'addEvent' }));
          this.$emit('eventData', ObjPlannerEvent);
        }
        else {
          this.apiResponseCode = APP_CONST.RESPONSE_400;
        }
      }
      else {
        this.plannerEventObj.fileIds = this.getFileIds(this.fileArray);
        this.plannerEventObj.startDate = APP_UTILITIES.getFullDateWith2DigitYear(this.plannerEventObj.startDate);
        this.plannerEventObj.endDate = APP_UTILITIES.getFullDateWith2DigitYear(this.plannerEventObj.endDate);
        this.isValid = false;
        const response = await editHolidays(this.plannerEventObj);
        if (response.status == APP_CONST.RESPONSE_200) {
          this.apiResponseCode = APP_CONST.RESPONSE_200;
          this.$emit('emitMode', 'View');
          this.plannerEventObj.isEvent = false;
          this.plannerEventObj.isEdit = true;
          const ObjPlannerEvent = JSON.parse(JSON.stringify({ ...this.plannerEventObj, documentDtos: this.createFileArrayForView(this.fileArray), state: 'updateEvent' }));
          this.$emit('eventData', ObjPlannerEvent);
        }
        else {
          this.apiResponseCode = APP_CONST.RESPONSE_400;
        }
      }

    }
  }
  


  clearSelectedCheckBox(){
    this.programData.forEach((program:any) => {
      if(program.hasOwnProperty('isChecked')){
        program.isChecked=false;
      }
    });
  }

  resetDropdown(){
    this.dropdownSiteData && this.dropdownSiteData.forEach((element:any) => {
      element.checked = false;
    });
    this.multiSelectSitesDropdown.dropdownList = this.dropdownSiteData;
  }

  callApi() {
    if (this.eventMode != 'Edit') {
      this.plannerEventObj.fileIds = this.getFileIds(this.fileArray);
      this.isValid = false;
      programPlanner.addProgramEvent(this.createServerPayload(this.plannerEventObj)).then(() => {
        if (programPlanner.EventAddResponse.status === APP_CONST.RESPONSE_200) {
          this.apiResponseCode = APP_CONST.RESPONSE_200;
          this.clearSelectedCheckBox();
          this.allSelected=false;
          this.resetDropdown();
          const ObjPlannerEvent = JSON.parse(JSON.stringify({ ...this.plannerEventObj, documentDtos: this.createFileArrayForView(this.fileArray), state: 'addEvent' }));
          ObjPlannerEvent.isEvent = true;
          ObjPlannerEvent.isEdit = true;
          ObjPlannerEvent.id = programPlanner.EventAddResponse.data;
          this.$emit('eventData', ObjPlannerEvent);          
        }
        else {
          this.apiResponseCode = APP_CONST.RESPONSE_400;
        }

      });
    }
    else {
      this.plannerEventObj.fileIds = this.getFileIds(this.fileArray);
      this.plannerEventObj.date = APP_UTILITIES.getFullDateWith2DigitYear(this.plannerEventObj.date);
      this.isValid = false;
      programPlanner.updateProgramEventData(this.plannerEventObj).then((res: any) => {
        if (res.status === APP_CONST.RESPONSE_200) {
          this.apiResponseCode = APP_CONST.RESPONSE_200;
          this.$emit('emitMode', 'View');
          const ObjPlannerEvent = JSON.parse(JSON.stringify({ ...this.plannerEventObj, documentDtos: this.createFileArrayForView(this.fileArray), state: 'updateEvent' }));
          ObjPlannerEvent.isEdit = true;
          this.$emit('eventData', ObjPlannerEvent);
        }
        else {
          this.apiResponseCode = APP_CONST.RESPONSE_400;
        }

      });

    }
  }
  resetObj() {
    this.addedSiteList=[];
    this.fileArray = [];
    this.fileSizeError = false;
    this.fileTypeError = false;
    this.maxFileError = false;
    this.allSelected=false;
    this.toggleDropDownStartTimeMobile = false;
    this.toggleDropDownStarttime = false;
    this.toggleDropDownEndTime = false;
    this.toggleDropDownEndTimeForMobile = false;
    this.errorObj.startTime.isTimeFormat = false;
    this.errorObj.endTime.isTimeFormat = false;
    this.plannerEventObj.isEvent=true;
    this.clearSelectedCheckBox();
    this.allSelected=false;
    this.resetDropdown();
    this.plannerEventObj = {
      id: 0,
      programId: 0,
      siteId: 0,
      name: '',
      location: '',
      date: '',
      startTime: '7:00 AM',
      endTime: '7:30 AM',
      notes: '',
      fileIds: [],
      startDate: '',
      endDate: '',
      isEvent: true,
      siteIds:[]
    };
    this.handleSaveButton();
  }
  createServerPayload(payload: any) {

    const form_data = {

    } as any;
    const keysArray: Array<string> = Object.keys(this.serverPayload);
    if (this.eventMode == 'Edit') {
      keysArray.push('id');
    }
    for (let key = 0; key < keysArray.length; key++) {
      const fieldValue: any = payload[keysArray[key].charAt(0).toLowerCase() + keysArray[key].slice(1)];
      if (this.eventMode == 'Edit') {

        form_data[keysArray[key].charAt(0).toLowerCase() + keysArray[key].slice(1)] = fieldValue;

      }
      else {

        form_data[keysArray[key]] = fieldValue;


      }

    }
    return form_data;
  }
  startTimeChange(ev: any) {
    if (!this.startTimeBlurr) {
      return false;
    }
    const time: string = ev.target.textContent.trim().toUpperCase().replace(/ /g, '');
    this.startTimeBlurr = false;
    if (ev.target.textContent) {
      if (APP_UTILITIES.validateHhMm12Format(time) || APP_UTILITIES.validateHhMm12FormatwithoutColon(time)) {
        this.errorObj.startTime.isTimeFormat = false;
        const convertTimeintoColon = APP_UTILITIES.convertTime(time);
        this.plannerEventObj.startTime = APP_UTILITIES.checkTimeIs24(convertTimeintoColon)
          ? APP_UTILITIES.convert12hrFormateTime(convertTimeintoColon)
          : convertTimeintoColon;
        const element: any = document.getElementById('startTime');
        element.innerHTML= this.plannerEventObj.startTime;
        this.plannerEventObj.endTime = this.validateTime(this.plannerEventObj.startTime, this.plannerEventObj.endTime)
          ? this.add30Min(APP_UTILITIES.convertTime12to24(convertTimeintoColon))
          : this.plannerEventObj.endTime;
      }
      else {
        this.errorObj.startTime.isTimeFormat = true;
      }

    }
    else {
      if (APP_UTILITIES.validateHhMm12Format(time)) {
        this.errorObj.startTime.isTimeFormat = false;
        this.plannerEventObj.startTime = APP_UTILITIES.checkTimeIs24(time)
          ? APP_UTILITIES.convert12hrFormateTime(time)
          : time;
      }
      else {
        this.plannerEventObj.startTime = '';
        this.errorObj.startTime.isTimeFormat = true;
      }
    }
    this.timeAutoscroll();
  }

  endTimeChange(ev: any) {
    if (!this.endTimeBlurr) {
      return false;
    }
    const time: string = ev.target.textContent.trim().toUpperCase().replace(/ /g, '');
    this.endTimeBlurr = false;
    if (ev.target.textContent) {
       
      if(APP_UTILITIES.validateHhMm12Format(time) || APP_UTILITIES.validateHhMm12FormatwithoutColon(time)) {        
        this.errorObj.startTime.isTimeFormat = false;
        const convertTimeintoColon = APP_UTILITIES.convertTime(time);
        this.plannerEventObj.endTime = APP_UTILITIES.checkTimeIs24(convertTimeintoColon)
          ? APP_UTILITIES.convert12hrFormateTime(convertTimeintoColon)
          : convertTimeintoColon;
        const element: any = document.getElementById('endTime');
        element.innerHTML= this.plannerEventObj.endTime;
        this.plannerEventObj.startTime = this.validateTime(this.plannerEventObj.startTime, this.plannerEventObj.endTime)
          ? this.sub30Min(APP_UTILITIES.convertTime12to24(convertTimeintoColon))
          : this.plannerEventObj.startTime;
      }
      else {
        this.errorObj.endTime.isTimeFormat = true;
      }

    }
    else {
      if (APP_UTILITIES.validateHhMm12Format(time)) {
        this.errorObj.endTime.isTimeFormat = false;
        this.plannerEventObj.endTime = APP_UTILITIES.checkTimeIs24(time)
          ? APP_UTILITIES.convert12hrFormateTime(time)
          : time;
      }
      else {
        this.plannerEventObj.endTime = '';
        this.errorObj.endTime.isTimeFormat = true;
      }
    }
    this.timeAutoscroll();

  }
  checkLength($event: any) {
    if (($event.target.textContent.length > 8) && ($event.keyCode !== 8) && ($event.keyCode !== 37) && ($event.keyCode !== 39)) {
      $event.preventDefault();
    }
    if ($event.keyCode == 13) {
      $event.preventDefault();
    }
  }
  public onInputStartTime($event: any) {
    if (($event.target.textContent.length > 8) && ($event.keyCode !== 37) && ($event.keyCode !== 39)) {
      $event.preventDefault();
      return false;
    }
    else {
      this.startTimeBlurr = true;
    }
  }
  public onInputEndTime() {
    this.endTimeBlurr = true;
  }


  replaceAMPM(time: string) {
    time = time.replace('PM', '');
    time = time.replace('AM', '');
    return time;
  }
  add30Min(time: string) {
    this.errorObj.endTime.isTimeFormat = false;
    const theAdd = new Date(APP_UTILITIES.getFullDate(new Date()) + ' ' + this.replaceAMPM(time));
    const finalDate = theAdd.setMinutes(theAdd.getMinutes() + 30);
    return (new Date(finalDate)
      .toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }));
  }
  sub30Min(time: string) {
    this.errorObj.startTime.isTimeFormat = false;
    const theAdd = new Date(APP_UTILITIES.getFullDate(new Date()) + ' ' + this.replaceAMPM(time));
    const finalDate = theAdd.setMinutes(theAdd.getMinutes() - 30);
    return (new Date(finalDate)
      .toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }));
  }
  validateData() {

    let validationValue: boolean = true;
    this.errorIndicator = false;
    const keysArray: Array<string> = Object.keys(this.plannerEventObj);
    const validateData = this.plannerEventObj.isEvent
      ? this.validatedFields
      : this.validatedHolidayFields;
    for (let key = 0; key < keysArray.length; key++) {
      if (validateData.includes(keysArray[key])) {
        const fieldValue: any = this.plannerEventObj[keysArray[key]].trim();
        if (fieldValue != '') {
          this.errorObj[keysArray[key]].isError = false;

        }
        else {
          this.errorObj[keysArray[key]].isError = true;
          validationValue = false;
          this.errorIndicator = true;

        }
      }

    }

    return validationValue;
  }
  dateChange(e: any, date: string) {
    if (date === 'startDate') {
      this.plannerEventObj.startDate = APP_UTILITIES.getFullDateWith2DigitYear(e);
    }
    else if (date === 'endDate') {
      this.plannerEventObj.endDate = APP_UTILITIES.getFullDateWith2DigitYear(e);
    }
    else {
      if (date === 'date') {
        this.plannerEventObj.date = APP_UTILITIES.getFullDateWith2DigitYear(e);
      }
    }
  }
  resetErrorObj() {
    this.errorIndicator = false;
    this.onSubmit = false;
    this.dateResponseError = false;
    const keysArray: Array<string> = Object.keys(this.errorObj);
    for (let key = 0; key < keysArray.length; key++) {
      this.errorObj[keysArray[key]].isError = false;
      if (this.errorObj[keysArray[key]].hasOwnProperty('isTimeMissMatch')) {
        this.errorObj[keysArray[key]].isTimeMissMatch = false;
      }
    }
  }
  mounted() {
    this.generateTimeInterval();
  }

  handleSaveButton(){
    const buttonId:any=document.getElementById('progressBareventBtn');
    if(buttonId.style.background){
      buttonId.removeAttribute('style');
    }
  }

  checkForDate() {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  }

  generateTimeInterval() {
    const min_interval = 15;
    let zeroth_time = 0;
    const ap = ['AM', 'PM'];
    for (let i = 0; zeroth_time < 24 * 60; i++) {
      const hh = Math.floor(zeroth_time / 60);
      const mm = (zeroth_time % 60);

      let hr;
      if (hh % 12 == 0) {
        hr = 12;
      }
      else {
        hr = hh % 12;
      }
      this.timeList[i] = ('' + (hr)).slice(-2) + ':' + ('0' + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)];

      zeroth_time = zeroth_time + min_interval;
    }
  }
  checkBoxHander(e: any) {
    this.plannerEventObj.isEvent = !e.target.checked;
    this.maxFileError = false;
    this.fileTypeError = false;
    this.fileSizeError = false;
    this.resetErrorObj();
  }
  fileHandler(file: any) {
    const checkBothArrayLength = this.filterFileAccordingIsEvent(this.fileArray).length + file.length;
    if (checkBothArrayLength <= 5) {
      this.maxFileError = false;
      this.fileTypeError = false;
      this.fileSizeError = false;
      for (let fileItem = 0; fileItem < file.length; fileItem++) {
        if (this.checkFileType(APP_UTILITIES.getFileNameAndtype(file[fileItem].name).ext)) {

          if (file[fileItem].size <= APP_CONST.UPLOAD_FILE_MB) {
            const formData = new FormData();
            let newFileName: string = '';
            if (this.checkDuplicateFile(file[fileItem])) {
              const fileArray: Array<any> = JSON.parse(JSON.stringify(this.fileArray));
              fileArray.push({ file: file, fileNameWithoutType: APP_UTILITIES.getFileNameAndtype(file[fileItem].name).fileName, fileName: file[fileItem].name, isEvent: this.plannerEventObj.isEvent });
              newFileName = APP_UTILITIES.renameFile(this.filterFileAccordingIsEvent(fileArray))[APP_UTILITIES.renameFile(this.filterFileAccordingIsEvent(fileArray)).length - 1] + '.' + APP_UTILITIES.getFileNameAndtype(file[fileItem].name).ext;
              const myFile = new File([file[fileItem]], newFileName);
              formData.append('formFile', myFile);
              this.callUploadFileApi(formData, myFile);
            }
            else {
              formData.append('formFile', file[fileItem]);
              this.callUploadFileApi(formData, file[fileItem]);
            }


          }
          else {
            this.fileTypeError = false;
            this.fileSizeError = true;

          }
        }
        else {
          this.fileSizeError = false;
          this.fileTypeError = true;
        }
      }
    }
    else {
      this.maxFileError = true;
      this.fileTypeError = false;
      this.fileSizeError = false;

    }

  }

  downLoadFile(ulr: string, localUrl: string) {
    if (ulr && !this.isMobileView) {
      window.open(ulr, '_self');
    }
    else if (localUrl && !this.isMobileView) {
      window.open(localUrl, '_self');
    }
  }
  callHover(index: number, text: string,$event: any) {
    this.hoverText = text;
    this.hovering = true;
    this.hoverIndex = index;
    const boundBox = $event && $event.target.getBoundingClientRect();
    const coordX = boundBox.left;
    const coordY = boundBox.top;
    this.hoverStyleObj = {
      top:(coordY + 50).toString() + 'px',
      left:(coordX + 50).toString() + 'px',
    };
  }

  callHoverOut() {
    this.hoverText = '';
    this.hovering = false;
    this.hoverIndex = -1;
  }
  callUploadFileApi(formData: any, file: any) {
    if (this.plannerEventObj.isEvent) {
      uploadEventFile(formData).then((response: any) => {
        if (response.status == APP_CONST.RESPONSE_200) {
          this.fileArray.push({ id: response.data, fileUrl: process.env.VUE_APP_BASE_DOWNLOAD_FILE_URL + API_CONST.DOWNLOAD_EVENT_FILE + response.data + '/' + file.name, isEvent: this.plannerEventObj.isEvent, file: file, fileNameWithoutType: APP_UTILITIES.getFileNameAndtype(file.name).fileName, fileName: file.name });
        }
      });
    }
    else {
      uploadHolidayFile(formData).then((response: any) => {
        if (response.status == APP_CONST.RESPONSE_200) {
          this.fileArray.push({ id: response.data, fileUrl: process.env.VUE_APP_BASE_DOWNLOAD_FILE_URL + API_CONST.DOWNLOAD_HOLIDAY_FILE + response.data + '/' + file.name, isEvent: this.plannerEventObj.isEvent, file: file, fileNameWithoutType: APP_UTILITIES.getFileNameAndtype(file.name).fileName, fileName: file.name });
        }
      });

    }
  }
  checkFileType(ext: string) {
    const fileType = ['jpg', 'pdf', 'csv', 'jpeg', 'gif', 'png', 'doc', 'txt', 'docx', 'xls', 'xlsx', 'ppt', 'txt', 'pptx', 'jfif'];
    return fileType.some((filetype: any) => {
      return filetype.toLowerCase() == ext.toLowerCase();
    });
  }

  checkDuplicateFile(file:File){
    return this.filterFileAccordingIsEvent(this.fileArray).some((fileobj: any) => {
      const x = this.replaceWhiteSpaceFromFileName1(file.name) == this.replaceWhiteSpaceFromFileName1(fileobj.fileName);
      return x;
    });
  }
  replaceWhiteSpaceFromFileName1(name: string) {
    if (name) {
      return name.replace(/\s/g, '');
    }
    return '';
  }
  deleteFileHandler(fileObj: any, index: number, event: any) {

    const files = this.fileArray.filter((obj: any) => obj.id != fileObj.id);
    this.fileArray = files;
    if (files.length == 0) {
      this.fileTypeError = false;
      this.fileSizeError = false;
      this.maxFileError = false;
    }
    event.stopPropagation();
  }

  DateFormat(data: string) {
    const date = APP_UTILITIES.formatShortDate(data);
    return date;
  }
  close() {
    this.errorTimeMisMatch = false;
    const closemodal: any = document.getElementsByClassName('menuable__content__active')[0];
    if (UIkit.offcanvas('#eventsWrap') || closemodal) {
      this.pointerEventNone = false;
      UIkit.offcanvas('#eventsWrap').hide();
      this.$emit('noActionPointer', this.pointerEventNone);
      if (!this.pointerEventNone) {
        setTimeout(() => {
          this.closeDeletePopup();
          this.resetErrorObj();
          this.resetObj();
        }, 500);
      }
      this.viewPopup = false;
      this.viewInfoPopup = false;
      this.isValid = true;
      this.apiResponseCode = 0;
      this.showSeeMoreButton=false;
      this.showSeeLessButton=false;
      this.$emit('emitMode', 'View');
      const startTimeElement: any = document.getElementById('startTime');
      if(startTimeElement) {
        startTimeElement.innerHTML= this.plannerEventObj.startTime;
      }
      const endTimeElement: any = document.getElementById('endTime');
      if(endTimeElement) {
        endTimeElement.innerHTML= this.plannerEventObj.endTime;
      }

    }
    const htmlElem = <any>document.getElementsByTagName('html')[0];
    if(closemodal){
      setTimeout(()=>{
        const closepopup: any = document.getElementsByClassName('menuable__content__active')[0];
        closepopup.classList.add('slide-out');
        htmlElem.classList.add('overflow-page');
      },500);
      setTimeout(()=>{
        closemodal.style.display = 'none';
      },900);
      setTimeout(()=>{
        if(closemodal && closemodal.style.display == 'none'){
          htmlElem.classList.remove('overflow-page');
          this.$emit('loadState', 'reLoad');
        }
      },910);
  
    }         
    this.closeCopyEventRevertPop();
    const eventDrawer:any = document.getElementById('eventView') as HTMLElement ;
    eventDrawer && eventDrawer.scrollTo(0,0);
      
  }
  public validateTime(start_time: string, end_time: string) {
    const start_hh_mm = APP_UTILITIES.convertTime12to24(start_time).split(':');
    const end_hh_mm = APP_UTILITIES.convertTime12to24(end_time).split(':');
    const startHour = +start_hh_mm[0], startMinute = +start_hh_mm[1], endHour = +end_hh_mm[0], endMinute = +end_hh_mm[1];
    const startTimeObject: any = new Date();
    startTimeObject.setHours(startHour, startMinute, APP_CONST.ZERO, APP_CONST.ZERO);
    const endTimeObject: any = new Date();
    endTimeObject.setHours(endHour, endMinute, APP_CONST.ZERO, APP_CONST.ZERO);
    const diffMins = APP_UTILITIES.diff_minutes(endTimeObject, startTimeObject);
    if ((diffMins <= 30) || (start_time && !end_time) || (!start_time && end_time)) {
      return true;
    }
    else {
      return false;
    }
  }
  beforeMount() {
    this.getProgramId();
    this.getSiteId();

  }

  public getProgramId() {
    let pID = 0;
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    this.userRoles = JSON.parse(userRoles);
    this.userRoles && this.userRoles.forEach((item: any, index: number) => {
      if (item.hasOwnProperty('programId')) {
        this.plannerEventObj.programId = item.programId;
        pID = item.programId;
      }
      else {
        const programId: any = APP_UTILITIES.getCookie('programId');
        this.plannerEventObj.programId = Number(programId);
        pID = Number(programId);
      }

      if (item.hasOwnProperty('roleId')) {
        this.currentRoleId = item.roleId;
      }

    });
    return this.plannerEventObj.programId = pID;
  }

  public getSiteId() {
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    this.userRoles = JSON.parse(userRoles);
    this.userRoles && this.userRoles.forEach((item: any, index: number) => {
      if (item.hasOwnProperty('siteId')) {
        this.plannerEventObj.siteId = item.siteId;
      }
      else {
        const siteId: any = APP_UTILITIES.getCookie('siteId');
        this.plannerEventObj.siteId = Number(siteId);
      }
    });
  }
  editEvent() {
    this.$emit('editEvent');
  }

  deleteEventConfirmation() {
    this.deleteConPopup = true;
  }

  closeDeletePopup() {
    this.deleteConPopup = false;
  }

  deleteEvent() {
    if (this.plannerEventObj.isEvent) {
      deleteEvent(this.plannerEventObj).then((res: any) => {
        const ObjPlannerEvent = JSON.parse(JSON.stringify({ ...this.plannerEventObj, state: 'deleteEvent' }));
        this.$emit('eventData', ObjPlannerEvent);
        this.closeDeletePopup();
        this.close();
      });
    }
    else {
      deleteholidayEvent(this.plannerEventObj).then((res: any) => {
        const ObjPlannerEvent = JSON.parse(JSON.stringify({ ...this.plannerEventObj, state: 'deleteEvent' }));
        this.$emit('eventData', ObjPlannerEvent);
        this.closeDeletePopup();
        this.close();
      });
    }

  }

  deleteCopiedEvent(id: number) {
    this.plannerEventObj.id = (id === this.plannerEventObj.id)
      ? this.plannerEventObj.id
      : id;
    deleteEvent(this.plannerEventObj).then((res: any) => {
      if (res.status === APP_CONST.RESPONSE_200) {
        this.closeCopyEventRevertPop();
      }
      this.disableCopyBtn = false;
      const ObjPlannerEvent = JSON.parse(JSON.stringify({ ...this.plannerEventObj, state: 'deleteEvent' }));
      this.$emit('eventData', ObjPlannerEvent);
    });
  }

  closeCopyEventRevertPop() {
    this.copyEventRevert = false;
    this.disableCopyBtn = false;
    this.copiedEventId = -1;
  }

  checkForCorrectDate(type: string) {
    let startDate = this.plannerEventObj.startDate;
    this.dateType = '';
    this.dateResponseError = APP_CONST.FALSE;
    if (type === APP_CONST.END_DATE) {
      if (startDate.length === APP_CONST.ZERO) {
        this.dateResponseError = APP_CONST.TRUE;
        this.dateResponse = APP_CONST.FILL_START;
        this.allFieldRequired = APP_CONST.FALSE;
        this.dateType = 'startDate';
        setTimeout(() => {
          startDate = '';
          this.plannerEventObj.startDate = '';
          this.plannerEventObj.endDate = '';
          this.errorObj.startDate.isError = true;
        });
      }
      if (Date.parse(this.plannerEventObj.endDate) < Date.parse(this.plannerEventObj.startDate)) {
        this.dateResponseError = APP_CONST.TRUE;
        this.dateResponse = APP_CONST.ALERT_END_DATE;
        this.allFieldRequired = APP_CONST.FALSE;
        this.dateType = type;
        setTimeout(() => {
          this.plannerEventObj.endDate = '';
          this.errorObj.endDate.isError = true;
        });
      }
    }
    else {
      if (Date.parse(this.plannerEventObj.endDate) < Date.parse(this.plannerEventObj.startDate)) {
        this.dateResponseError = APP_CONST.TRUE;
        this.dateResponse = APP_CONST.ALERT_END_DATE;
        this.allFieldRequired = APP_CONST.FALSE;
        this.dateType = 'endDate';
        setTimeout(() => {
          this.plannerEventObj.endDate = '';
          this.errorObj.endDate.isError = true;
        });
      }
    }
  }

  createFileArrayForView(files: any) {
    const newFileArray = [];
    const filesFilter = this.filterFileAccordingIsEvent(files);
    for (let i = 0; i < filesFilter.length; i++) {
      newFileArray.push({
        'id': filesFilter[i].id,
        'fileName': filesFilter[i].file.name,
        fileUrl: filesFilter[i].isEvent
          ? process.env.VUE_APP_BASE_DOWNLOAD_FILE_URL + API_CONST.DOWNLOAD_EVENT_FILE + filesFilter[i].id + '/' + filesFilter[i].file.name
          : process.env.VUE_APP_BASE_DOWNLOAD_FILE_URL + API_CONST.DOWNLOAD_HOLIDAY_FILE + filesFilter[i].id + '/' + filesFilter[i].file.name

      });
    }
    return newFileArray;
  }

  getFileIds(files: any) {
    const ids: any = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].isEvent == this.plannerEventObj.isEvent) {
        ids.push(files[i].id);
      }
    }
    return ids;
  }
  filterFileAccordingIsEvent(array: any) {
    const fileArray = array.filter((fileObj: any) => fileObj.isEvent == this.plannerEventObj.isEvent);
    return fileArray;
  }

  openPopup() {
    this.viewPopup = true;
  }

  openInfoPopup(){
    this.viewInfoPopup = true;
  }

  closeInfoPopup(){
    this.viewInfoPopup = false;
  }

  closePopup() {
    this.viewPopup = false;
  }
  checkNotesChange(delta: any, oldDelta: any, source: string) {
    const s = Object(this.$refs.notes);
    s.quill.deleteText(650, 1, source);
    if (this.eventMode != 'view') {
      delta.ops.forEach((element: any) => {
        const result1 = element.hasOwnProperty('attributes');
        if (result1) {
          const result2 = element['attributes'].hasOwnProperty('link');
          if (result2) {
            let linkName = element['attributes'].link;
            if (((linkName.substring(0, 4)).toLowerCase() + linkName.substring(4, 7)) !== 'http://' && ((linkName.substring(0, 5)).toLowerCase() + linkName.substring(5, 8)) !== 'https://') {
              linkName = 'http://' + linkName;
              s.quill.format('link', linkName, source);
            }
          }
        }
      });
    }
  }

  async downloadSingalEvent(e: any, plannerEventObj: object) {
    e.preventDefault();
    this.$emit('downloadEvent', e, plannerEventObj);
  }

  copyCurrentEvent(){
    this.disableCopyBtn = true;
    const eventId = this.viewEventId > 0
      ? this.viewEventId
      : this.idOfEvent;  
    const { programId, siteId } = APP_UTILITIES.coreids();
    copyEvent(eventId).then((response) => {
      if (response.status === APP_CONST.RESPONSE_200) {
        this.copiedEventId = response.data.id;
        this.copyEventRevert = true;
        const data = JSON.parse(JSON.stringify(response.data));
        data.isEvent = this.plannerEventObj.isEvent
          ? true
          : data.isEvent;
        if(!this.idOfEvent){
          const getEventsParam = { programId: programId, productId: programConfigure.productId, siteId: siteId };
          plannerStore.events(getEventsParam);
        }
        else{
          plannerStore.updateEventList(data);
        }  
        plannerStore.updateCopyEventStatus(true);
      } 
    });
  }        

  onlyNumber ($event: KeyboardEvent){
    const allowedAlphabets = ['A', 'a', 'P', 'p', 'M','m'];
    const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ' ', 'A', 'a', 'P', 'p', 'M','m'];
    const keyPressed: string = $event.key;
    const eventTarget:any = $event.target;
    if (!keysAllowed.includes(keyPressed)  || (allowedAlphabets.includes(keyPressed) && eventTarget.textContent.toLowerCase().includes(keyPressed.toLowerCase()))) {
      $event.preventDefault();
    }
  }  
  selectAllSite(e: {target: {checked: boolean}}){
    this.addedSiteList = [];
    const selectedValue=e.target.checked;
    this.programData.forEach((program:any) => {
      program.isChecked=selectedValue;
    });
    this.programData=JSON.parse(JSON.stringify(this.programData));   
    this.checkselectionstate();
    for(let i=0 ; i<=this.programData.length ; i++) {
      if(selectedValue){
        this.addedSiteList.push(this.programData[i].siteName);
      }
      else{
        this.addedSiteList = [];
      }
    }
  }

  selectSite(siteDetails:{siteId: number; isChecked: boolean;siteName: string}){
    const siteIndex = this.programData.findIndex((program:any) => program.siteId==siteDetails.siteId);
    this.programData[siteIndex].isChecked=siteDetails.isChecked;
    this.checkselectionstate();
    if(siteDetails.isChecked && !this.addedSiteList.includes(siteDetails.siteName)){
      this.addedSiteList.push(siteDetails.siteName);
    }
    else if(!siteDetails.isChecked && this.addedSiteList.includes(siteDetails.siteName)){
      const addedSiteIndex = this.addedSiteList.findIndex((siteName:any) => siteName == siteDetails.siteName);
      this.addedSiteList.splice(addedSiteIndex,1);
    }
  }
  checkselectionstate(){
    const selectedSiteCount = this.programData.filter((program: any) => program.isChecked);
    if(selectedSiteCount.length >= 0 && (selectedSiteCount.length !== this.programData.length)) {
      this.allSelected = false;
    }
    else{
      this.allSelected = true;
    }
  }

}
