
import { Vue } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import html2canvas from 'html2canvas';
import { printHTMLTOPdf } from '@/services/program/plannerService';
import plannerStore from '@/store/modules/plannerStore';
export default class Printutility extends Vue {

  public static getPrintView(elementId: any, breakHeight: any, width: any, scale: any, fileName: any, headerElementId: any, threshold: number, compoentType: any, landscape: any) {
    return new Promise((resolve, reject) => {
      const element: any = document.getElementById(elementId);
      if (!element) {
        resolve('Element Not Found');
        return;
      }
      let elementHeight;
      if (elementId == 'printTaskComponent' && width == 1144) {
        elementHeight = element.height;
      }
      else {
        elementHeight = element.offsetHeight;
      }
      if (elementId == 'PrintingCalenderEvent') {
        if (document.getElementsByClassName('ds-week-view-pane').length > 0 && document.getElementsByClassName('ds-week-header').length > 0) {
          const headerElement: any = document.getElementsByClassName('ds-week-header');
          const weekHeaderHeight = headerElement[1].offsetHeight;
          for (const s of document.getElementsByClassName('ds-week-view-pane')) {
            const weekHeaderNavigation = 60;
            elementHeight = weekHeaderHeight + weekHeaderNavigation + s.scrollHeight;

          }
        }
      }
      if (elementId == 'eventSinglePrintView') {
        elementHeight = elementHeight + 100;
      }
      let marginRight = 0;
      if (elementId == 'quality-line-Graph-Print') {
        marginRight = 0;
      }
      else {
        marginRight = 20;
      }
      const canvasScale = scale
        ? scale
        : 1;
      let headerWidth: any;
      if (headerElementId !== 'HeaderCalenderEvent') {
        headerWidth = width
          ? width
          : element.offsetWidth + marginRight;

      }
      else {
        if (headerElementId) {
          const headerElement: any = document.getElementById(headerElementId);
          headerElement.style.width = element.offsetWidth + 'px';
          headerWidth = width
            ? width
            : headerElement.offsetWidth;

        }
      }
      html2canvas(element, {
        useCORS: true,
        width: width
          ? width
          : element.offsetWidth + marginRight,
        height: elementHeight,
        scale: canvasScale,
        onclone: (clonedDoc: any) => {
          if (elementId == 'Attendance-data-print' && clonedDoc.getElementById('Attendance-data-print')) {
            clonedDoc.getElementById('Attendance-data-print').style.opacity = 1;
            clonedDoc.getElementById('Attendance-data-print').style.position = 'relative';
          }
          else if (clonedDoc.getElementById('nextMonth') && clonedDoc.getElementById('backMonth') && clonedDoc.getElementById('monthBtn')) {
            clonedDoc.getElementById('nextMonth').style.display = 'none';
            clonedDoc.getElementById('backMonth').style.display = 'none';
            clonedDoc.getElementById('monthBtn').style.display = 'none';
          }

          if (elementId == 'quality-line-Graph-Print' && clonedDoc.getElementById('quality-line-Graph-Print')) {
            clonedDoc.getElementById('quality-line-Graph-Print').style.opacity = 1;
            clonedDoc.getElementById('quality-line-Graph-Print').style.zIndex = 'initial';
            clonedDoc.getElementById('quality-line-Graph-Print').style.position = 'initial';

          }

          if (elementId == 'calendar-view') {
            if (clonedDoc.getElementsByClassName('ds-ev-title')) {
              for (const s of clonedDoc.getElementsByClassName('ds-ev-title')) {
                s.style.padding = '0 10px 10px 10px';
              }
            }

            if (clonedDoc.getElementsByClassName('ds-calendar-event-menu')) {
              for (const s of clonedDoc.getElementsByClassName('ds-calendar-event-menu')) {
                s.style.padding = '0 0 6px 0';
              }

            }

            if (clonedDoc.getElementsByClassName('ds-calendar-event')) {
              for (const s of clonedDoc.getElementsByClassName('ds-calendar-event')) {
                s.style.padding = '0 0 9px 0';
                s.style.height = '120%';
              }
            }

            if (clonedDoc.getElementsByClassName('v-menu__activator')) {
              for (const s of clonedDoc.getElementsByClassName('v-menu__activator')) {
                s.style.height = '109%';
              }
            }
          }
          if (elementId == 'PrintingCalenderEvent') {
            for (const s of clonedDoc.getElementsByClassName('ds-ev-title')) {
              s.style.display = 'block';
            }
          }
          if (clonedDoc.getElementById('downloadSortingHeader')) {
            clonedDoc.getElementById('downloadSortingHeader').style.display = 'none';
          }
          if (elementId == 'PrintingCalenderEvent') {
            if (clonedDoc.getElementsByClassName('event-list-body')) {
              for (const s of clonedDoc.getElementsByClassName('event-list-body')) {
                s.style.border = `1px solid ${APP_CONST.STYLE.COLOR.NEUTRAL_900}`;


              }

            }
            if (clonedDoc.getElementsByClassName('event-download')) {
              for (const s of clonedDoc.getElementsByClassName('event-download')) {
                s.style.display = 'block';
              }
            }
          }

          //Quality Graph Component
          if (clonedDoc.getElementById('dropIconForPrint') && clonedDoc.getElementById('hideDropDownForPrint') && clonedDoc.getElementById('printQualityGraphMargin')) {
            clonedDoc.getElementById('dropIconForPrint').style.display = 'none';
            clonedDoc.getElementById('hideDropDownForPrint').style.border = 'none';
            clonedDoc.getElementById('hideDropDownForPrint').style.padding = ' 10px 9px';
            clonedDoc.getElementById('hideDropDownForPrint').style.whiteSpace = 'normal';
            clonedDoc.getElementById('hideDropDownForPrint').style.wordBreak = 'break-all';
            clonedDoc.getElementById('hideDropDownForPrint').style.height = 'auto';
            clonedDoc.getElementById('printQualityGraphMargin').style.marginBottom = '35px';

          }
          if (clonedDoc.getElementById('printBtn') && clonedDoc.getElementById('PrintProgramName') && clonedDoc.getElementById('PrintProgramName')) {
            clonedDoc.getElementById('printBtn').style.display = 'none';
            clonedDoc.getElementById('PrintProgramName').style.display = 'block';
            clonedDoc.getElementById('siteCaption').style.float = 'right';

            const siteCaption = clonedDoc.getElementById('siteCaption');
            siteCaption.style.display = 'block';
            siteCaption.style.marginRight = '15px';
            siteCaption.style.marginTop = '14px';

            if (clonedDoc.getElementById('QualityGraphPrintMargin')) {
              const QualityGraphPrintMargin = clonedDoc.getElementById('QualityGraphPrintMargin');
              QualityGraphPrintMargin.style.marginTop = '15px';
            }

          }
          if (clonedDoc.getElementsByClassName('ds-week-view-pane')) {
            for (const s of clonedDoc.getElementsByClassName('ds-week-view-pane')) {
              s.style.height = '100%';
              s.style.borderBottom = `solid 1.5px ${APP_CONST.STYLE.COLOR.NEUTRAL_500}`;
            }

          }
          if (elementId == 'PrintingCalenderEvent') {
            if (clonedDoc.getElementsByClassName('ds-week-view')) {
              for (const s of clonedDoc.getElementsByClassName('ds-week-view')) {
                s.style.position = 'relative';
                s.style.height = '99%';
              }
              for (const s of clonedDoc.getElementsByClassName('ds-week-view-pane')) {
                s.style.borderBottom = 'none';
              }

            }
          }
          if (clonedDoc.getElementById('HideGraphForPrintQualityData') && clonedDoc.getElementById('graphForPrintQualityData')) {
            clonedDoc.getElementById('graphForPrintQualityData').style.display = 'none';
            clonedDoc.getElementById('HideGraphForPrintQualityData').style.visibility = 'inherit';
          }
          if (clonedDoc.getElementsByClassName('ds-week-header-day')) {
            for (const s of clonedDoc.getElementsByClassName('ds-week-header-day')) {
              s.style.visibility = 'visible';
              s.innerHTML = s.innerText.trim().charAt(0);
            }

          }
          if (clonedDoc.getElementsByClassName('ds-week-weekday')) {
            for (const s of clonedDoc.getElementsByClassName('ds-week-weekday')) {
              s.style.visibility = 'visible';
              s.style.marginRight = '13px';
              s.innerHTML = s.innerText.trim().charAt(0);
            }

          }
          if (elementId == 'printTaskComponent') {
            clonedDoc.getElementById('printTaskComponent').style.width = '1144px';
          }
          if (elementId == 'calendar-view') {
            clonedDoc.getElementById('calendar-view').style.width = '1144px';
          }
        }
      })
        .then(async (canvas) => {
          let headerBase64;
          if (headerElementId) {
            headerBase64 = await this.headerHtml(headerElementId, canvasScale, headerWidth, elementId);
          }

          const splitImageHeight = breakHeight
            ? breakHeight
            : 1275;
          const transparencyThreshold = threshold
            ? threshold
            : 99;
          const finalArray = [];
          let tempArray = [];
          let checkValueOfTemp = false;
          for (let j = 0; j < canvas.height; j += 1) {

            j = j
              ? j + splitImageHeight - 1
              : j + splitImageHeight;

            if (j > canvas.height) {
              finalArray.push(canvas.height);
              break;
            }
            const percentValue: any = await this.imageManipulation(j, canvas.width, canvas, canvas.height, compoentType);
            if (percentValue.percent > transparencyThreshold) {
              finalArray.push(percentValue.position);
            }
            else {
              tempArray = [];
              for (let row = j; row > j - 100; row--) {
                const percent: any = await this.imageManipulation(row, canvas.width, canvas, canvas.height, compoentType);
                if (percent.percent > transparencyThreshold) {
                  checkValueOfTemp = true;
                  j = percent.position;
                  finalArray.push(percent.position);
                  tempArray = [];
                  break;
                }
                else {
                  tempArray.push(percent);
                }
              }
              if (!checkValueOfTemp) {
                const checkLastValue = tempArray.sort(this.sortByProperty('percent'))[tempArray.length - 1];
                finalArray.push(checkLastValue.position);
                j = checkLastValue.position;
              }
              checkValueOfTemp = false;
            }
          }
          finalArray.unshift(0);
          const response = await this.createBase64(finalArray, canvas, splitImageHeight, headerBase64, fileName, landscape);
          resolve(response);
        });
    });

  }
  public static async headerHtml(headerElementId: any, headerScale: any, headerWidth: any, elementId: any) {
    return new Promise((resolve, reject) => {
      const headerHtml: any = document.getElementById(headerElementId);
      html2canvas(headerHtml, {

        scale: headerScale
          ? headerScale
          : 1, width: headerWidth, onclone: (clonedDoc: any) => {
          const zoom = ((window.outerWidth) / window.innerWidth) * 100;
          if (headerElementId == 'Attendance-header-print' && clonedDoc.getElementById('Attendance-header-print')) {
            clonedDoc.getElementById('Attendance-header-print').style.opacity = 1;
            clonedDoc.getElementById('Attendance-header-print').style.position = 'relative';
          }
          if (headerElementId == 'HeaderCalenderEvent' && clonedDoc.getElementById('HeaderCalenderEvent')) {
            if (zoom <= 82 && clonedDoc.getElementById('event')) {
              clonedDoc.getElementById('event').style.width = 'calc(100% - 515px)';
            }
            else if (zoom <= 92 && clonedDoc.getElementById('event')) {
              clonedDoc.getElementById('event').style.width = 'calc(100% - 497px)';
            }
            else if (zoom > 98 && clonedDoc.getElementById('event')) {
              clonedDoc.getElementById('event').style.width = 'calc(100% - 500px)';

            }
            if (navigator.appVersion.indexOf('Mac') == -1) {
              if (clonedDoc.getElementById('event-listHeader')) {
                clonedDoc.getElementById('event-listHeader').style.marginRight = '10px';
              }
            }
            if (navigator.appVersion.indexOf('Mac') == -1 && screen.width > 1280) {
              if (clonedDoc.getElementById('event-listHeader')) {
                clonedDoc.getElementById('event-listHeader').style.marginRight = '20px';
              }
            }
          }
          if (elementId.includes('eventSinglePrintView')) {
            if (clonedDoc.getElementById('event-listHeader')) {
              clonedDoc.getElementById('event-listHeader').style.display = 'none';
            }
            clonedDoc.getElementById('dateRangeforView').style.display = 'none';
            clonedDoc.getElementById('headsection').style.marginLeft = '40px';

          }
        }
      })
        .then(async (canvas) => {
          resolve(canvas);
        });
    });
  }
  public static async createBase64(finalArray: any, canvas: any, splitHieght: any, headerBase64: any, fileName: any, landscape: any) {
    return new Promise(async (resolve, reject) => {
      const base64Array: any = [];
      if (finalArray.length > 1) {
        for (let item1 = 0; item1 < finalArray.length; item1++) {
          if (finalArray.length - 1 != item1) {
            const finalHeight = finalArray[item1 + 1] - finalArray[item1];
            const base64FirstPosition = await this.createImage(finalArray[item1], canvas, finalHeight, canvas.width, headerBase64);
            base64Array.push(base64FirstPosition);
          }

        }
      }
      const response = await this.callApiForPdf(base64Array, fileName, landscape);
      resolve(response);
    });
  }
  public static sortByProperty(property: any) {
    return function (a: any, b: any) {
      if (a[property] > b[property]) {
        return 1;
      }
      else if (a[property] < b[property]) {
        return -1;
      }

      return 0;
    };
  }

  public static createImage(position: any, srcImg: any, splitHieght: any, width: any, headerUrl: any) {
    return new Promise((resolve, reject) => {
      const onePageCanvas: any = document.createElement('canvas');
      onePageCanvas.setAttribute('width', width);
      onePageCanvas.setAttribute('height', splitHieght);
      const ctx = onePageCanvas.getContext('2d');
      ctx.drawImage(srcImg, 0, position, width, splitHieght, 0, 0, width, splitHieght);
      if (headerUrl) {
        const combineHeight = splitHieght + headerUrl.height;
        const combineImage: any = document.createElement('canvas');
        const combineImageCanvasContext = combineImage.getContext('2d');
        combineImage.setAttribute('width', width);
        combineImage.setAttribute('height', combineHeight);
        combineImageCanvasContext.drawImage(headerUrl, 0, 0, width, headerUrl.height);
        combineImageCanvasContext.drawImage(onePageCanvas, 0, headerUrl.height, width, splitHieght);
        resolve(combineImage.toDataURL('image/png'));
      }
      else {
        resolve(onePageCanvas.toDataURL('image/png'));
      }

    });
  }
  public static imageManipulation(y: number, clientWidth: any, canvas: any, spHight: any, compoentType: any) {
    return new Promise((resolve, reject) => {
      let toltalCheck = 0;
      let whiteColor = 0;

      for (let x = 0; x < clientWidth; x += 1) {
        const c: any = canvas.getContext('2d');
        const p1 = c.getImageData(x, y, 1, 1).data;
        toltalCheck++;
        if (p1[0] == 0 && p1[1] == 0 && p1[2] == 0 && p1[3] == 0) { }
        else {
          const hex = '#' + ('000000' + this.rgbToHex(p1[0], p1[1], p1[2])).slice(-6);
          if (compoentType) {
            if (hex == APP_CONST.STYLE.COLOR.NEUTRAL_100) {
              whiteColor++;
            }
          }
          else {
            if (hex == APP_CONST.STYLE.COLOR.NEUTRAL_900 || hex == APP_CONST.STYLE.COLOR.NEUTRAL_600) {
              whiteColor++;
            }
          }
        }
      }
      const transparency = (whiteColor / toltalCheck) * 100;
      resolve({ percent: transparency, position: y });
    });
  }
  public static rgbToHex(r: any, g: any, b: any) {
    if (r > 255 || g > 255 || b > 255) {
      throw 'Invalid color component';
    }
    return ((r << 16) | (g << 8) | b).toString(16);
  }
  public static async callApiForPdf(base64Arr: any, fileName: string, landscape: any) {
    return new Promise((resolve, reject) => {
      if (base64Arr.length) {
        const dataToSend: any = {
          base64Data: base64Arr,
          landscape: landscape
            ? landscape
            : false,
          outputFilename: 'plannerPrint'
        };
        printHTMLTOPdf(dataToSend).then((res: any) => {
          if (res.status === APP_CONST.RESPONSE_200) {
            plannerStore.mutateDownloadingState(false);
            const bufferArray = this.base64ToArrayBuffer(res.data);
            const blobStore = new Blob([bufferArray], { type: 'application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blobStore);
              return;
            }
            const data = window.URL.createObjectURL(blobStore);
            const link = document.createElement('a');
            document.body.appendChild(link);
            link.href = data;
            link.target = '_blank';
            link.download = fileName + '.pdf';
            link.click();
            if (navigator.userAgent.indexOf('Safari') != -1) {
              setTimeout(() => {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(data);
              }, 100);
            }
            else {
              document.body.removeChild(link);
              window.URL.revokeObjectURL(data);
            }
            resolve(res.status);
          }
          else {
            resolve(res.status);
          }
        }).catch((error: any) => {
        });

      }
    });
  }
  public static base64ToArrayBuffer(data: any) {
    const bString = window.atob(data);
    const bLength = bString.length;
    const bytes = new Uint8Array(bLength);
    for (let i = 0; i < bLength; i++) {
      const ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
}