import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';


@Component({
  filters: {
    initialLetter: function (value: string) {
      if (!value) {
        return '';
      }
      value = value.toString();
      return value.charAt(0).toUpperCase();
    }
  },
  components: {
    'bouncing-preloader': BouncingPreloaderComponent
  }
})
export default class TaskItem extends Vue {

    private objScreenText: ScreenText = new ScreenText();
    public keys: [] = [];
    public status : string[] = ['Active', 'Done', 'Skip'];
    public getTasksStatusList: any = APP_CONST.GET_TASK_STATUSES;
    public openDropTaskStatus: boolean = false;
    public selectedTasksStatus: any = { index: 0, label: 'ACTIVE', path: 'planner-status-profile.svg', value: '1' };
    public dropDownTaskId: number = -1;
    public currentDate = APP_UTILITIES.getCurrentDate();
    public loggedInUserRole=0;
    taskIds: {id: number; checked: boolean}[] = [];

    @Prop()
    printComponent!: boolean;

    @Prop()
    showFilter!: boolean;

    @Prop()
    isLoading!: boolean;

    @Prop()
    ownerList!:[];

    @Prop()
    tasks!: [];

    @Prop()
    loggedInUser!: number;

    public showOwnerDropdown: boolean=false;
    public taskNumber: number=-1;
    public inputValue: string='';
    public hovering: boolean = false;
    public hoverText: string= '';
    public hoverStyleObj:any = {};
    public hoverId:number = -1;
    public newOwnerList:any=[];
    public selectedTaskId:number = -1;
    public showKebabMenu:boolean = false;

    @Watch('ownerList',{deep:true,immediate:true})
    updateOwnerList(val: any) {
      this.newOwnerList = val;
    }

    showStatusList(taskId:number) {
      this.selectedTaskId = taskId;
      this.showKebabMenu = !this.showKebabMenu;
    }

    getProfileData(taskUsers:any) {
      const ownerTask = taskUsers.filter((task:any)=> task.isOwner);
      return ownerTask.length
        ? ownerTask[0].user
        : [];      
    }

    formattedDate(date: string) {
      return date
        ?  APP_UTILITIES.formatDate(date, true, true)
        : '';
    }

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    getPic(type: string, base64: string) {
      let profileImageSrc = '';
      if (type !== '' || base64 !== '') {
        profileImageSrc = 'data:' + type + ';' + 'base64,' + base64;
      } 
      return profileImageSrc;
    }

    checkBoxHandler(ev: {target : {checked : boolean}}, id: number) {
      if(ev.target.checked) {
        this.$emit('taskIds', {id: id, checked: true});
      } 
      else {
        this.$emit('taskIds', {id: id, checked: false});
      }
    }  
      
    callHover(fname: string,lname: string, $event:any, taskId: number) {
      this.hoverText=fname+' '+lname;
      this.hovering=true;
      this.hoverId = taskId;
      const boundBox = $event && $event.target.getBoundingClientRect();
      const coordX = boundBox.left;
      const coordY = boundBox.top;
      this.hoverStyleObj = {
        top:(coordY + 25).toString() + 'px',
        left:(coordX + 10).toString() + 'px'
      };
    }
    callHoverOut() {
      this.hovering=false;
      this.hoverId = -1;
    }

    getColorCode(email:string){
      return APP_UTILITIES.getColorCode(email);
	  }
	  
    selectTaskStatus(status: number, index: number){
      this.selectedTasksStatus = status;
      this.$emit('updateTaskStatus', {status: status, taskId:index});
      this.openDropTaskStatus = false;
      this.showKebabMenu = false;
      this.selectedTaskId = -1;
    }

    openDropdown(type:string, index: number, close? :boolean) {
      if(type == 'status-open') {
			  this.dropDownTaskId = index;
		  	this.openDropTaskStatus = this.openDropTaskStatus
          ? APP_CONST.FALSE
          : (close
            ? APP_CONST.FALSE
            : APP_CONST.TRUE);
		  }
	  }
	
    openOwnerDD(index:number){
      this.taskNumber=index;
      this.showOwnerDropdown=!this.showOwnerDropdown;
      this.newOwnerList=this.ownerList;
      this.inputValue='';
    }

    clearSearchField(){
      this.inputValue='';
      this.newOwnerList=this.ownerList;
    }

    searchOwner(ownerList:{firstName: string; lastName: string}[],search: string){
      return ownerList.filter((el: {firstName: string; lastName: string})=> {
        return `${el.firstName +' '+el.lastName}`.toLowerCase().includes(search.toLowerCase());
      });
    }

    selectOwner(newOwnerId:number, taskId:number){
      this.hovering=false;
      this.showOwnerDropdown=!this.showOwnerDropdown;
      this.$emit('ownerInfo',{newOwnerId:newOwnerId, taskId:taskId});
    }

    viewPopup(taskId: number) {
      this.$emit('viewTask', {taskId: taskId});
    }

    public convertDateInGMT(date: string) {
      return APP_UTILITIES.convertDateInGMT(date);
    }
    mounted(){
      const { roleId } = APP_UTILITIES.coreids();
      this.loggedInUserRole=roleId;
    }
}