import { Vue, Component} from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from '@/utilities/commonFunctions';

@Component({})
export default class ProgramPlannerWelcome extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public roleId: number = 0;
    
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    mounted() {
      const {roleId} = APP_UTILITIES.coreids();
      this.roleId = roleId;
    }

    close(){
      this.$emit('close', {id:'program-planner-welcome'});
    }
}