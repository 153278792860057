import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import plannerStore from '@/store/modules/plannerStore';
import { deleteEvent, deleteholidayEvent } from '@/services/program/plannerService';

@Component({
  components: {
    'bouncing-preloader': BouncingPreloaderComponent
  }
})
export default class EventItem extends Vue {
    private objScreenText: ScreenText = new ScreenText();    
    public getEventMenuList: any = JSON.parse(JSON.stringify(APP_CONST.GET_EVENT_MENUS));
    public getEventMobileMenuList: any = JSON.parse(JSON.stringify(APP_CONST.GET_MOBILE_EVENT_MENUS));
    lastSorting:any={label: 'Date',sortField: 'event_start_timestamp', order: 2, class: 'date',type:'default'};
    lastSortingIndex=0;
    private authKey:string = '';
    private order:number = 0;
    private mountedref:boolean = false;
    lastdrop: boolean = false;
    public id: number | null = -1;
    public userRoles: any = APP_UTILITIES.getCookie('user_role');  
    public roles: any = JSON.parse(this.userRoles);
    public currentRoleId: number = this.roles && this.roles[0].roleId;
    private deleteConPopup: boolean = false;
    private hideKababdropdown: boolean= false;
    private copyEventRevert: boolean= false;
    private copiedEventId :number=-1;


    @Prop()
    isMobileView!: boolean;

    @Prop()
    events!: [];

    @Prop()
    isEventLoading!: boolean;

    @Prop()
    idOfEvent!:number;

    get eventCopyStatus(){
      return plannerStore.getCopyEventStatus;
    }

    mounted() { 
      this.sortHandling();
    }

    updated(){
      plannerStore.updateEventSortingData(this.lastSorting);
    }

    formatDate(date: string) {
      return APP_UTILITIES.formatShortDate(date);
    }

    @Watch('events',{deep:true})
    updateEvents(){
      /* istanbul ignore else */
      if(this.mountedref){
        this.authKey = APP_UTILITIES.authTokenKeyToManage()
          ? APP_UTILITIES.authTokenKeyToManage()
          : '';   
        if(this.authKey && this.events && this.events.length){
          this.getSortingDefault();
          this.mountedref= false;
        }
      }
      if(this.eventCopyStatus){
        this.lastSorting.order = this.lastSorting.order; //==1?2:1;
        const sortData=JSON.parse(JSON.stringify(this.lastSorting));
        /* istanbul ignore else */
        this.getSortedList(JSON.parse(JSON.stringify(sortData)),this.lastSortingIndex,this.isMobileView
          ?'mobile'
          :'desktop','addUpdateDelete');
        plannerStore.updateCopyEventStatus(false);
      }
    }

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    getSortedList(menu: any, index: number, type: string, eventDataType?:string) {
      const column = menu.sortField;
      const order = menu.order;
      /* istanbul ignore else */
      if ((this.events && !this.events.length) || !column) {
        return;
      }
      this.events = this.events
        ? this.events.sort(function (a, b) {
          const val1 = a[column]; const val2 = b[column];
          if ((order == 2) && (!eventDataType)) {
            return ((val1 < val2)
              ? -1
              : ((val1 > val2)
                ? 1
                : 0));
          }
          else if((order == 1) && (!eventDataType) ) {
            return ((val1 > val2)
              ? -1
              : ((val1 < val2)
                ? 1
                : 0));
          }
          else if((order == 2) && (eventDataType)){
            return ((val1 > val2)
              ? -1
              : ((val1 < val2)
                ? 1
                : 0));
          }
          else if((order == 1) && (eventDataType)){
            return ((val1 < val2)
              ? -1
              : ((val1 > val2)
                ? 1
                : 0));
          }
          else{
            return ((val1 < val2)
              ? -1
              : ((val1 > val2)
                ? 1
                : 0));
          }
        })
        : [];

      if(type=='desktop'){
        const data = this.getEventMenuList;
        const updateIconData = this.sortIconsUpdate(data,index , eventDataType);
        this.getEventMenuList = updateIconData;
        this.lastSortingIndex=index;
        this.lastSorting=this.getEventMenuList[index];
      }
      else{
        const data = this.getEventMobileMenuList; 
        const updateIconData = this.sortIconsUpdate(data,index , eventDataType);  
        this.getEventMobileMenuList = updateIconData;
        this.lastSortingIndex=index;
        this.lastSorting=this.getEventMobileMenuList[index];
      }
        
      this.setSortingDefault();
    }

    sortIconsUpdate(data:any, headerIndex:number, eventDataType?:string){
      for(let i=0;i<data.length;i++){
        /* istanbul ignore else */
        if(!eventDataType || eventDataType){
          if(i==headerIndex){
            const currentOrder = data[i].order;
            if(!eventDataType){
              data[i].order = currentOrder == 1
                ?2
                :1;
            }
            this.order = data[i].order;
          }
          else{
            data[i].order = 0;
          }
        }
      }
      return data;
    }

    sortEventAfterCreate(eventDataType:string){
      const sortData=JSON.parse(JSON.stringify(this.lastSorting));
      /* istanbul ignore else */
      // if(sortData.order===2){
      //  sortData.order=1
      // }
      this.getSortedList(JSON.parse(JSON.stringify(sortData)),this.lastSortingIndex,this.isMobileView
        ?'mobile'
        :'desktop', eventDataType);
    }

    openDetailsPopup(eventPayload:any){
      if(this.deleteConPopup){
        return;
      }
      this.$emit('detailsEventHandler', eventPayload);
    }

    setSortingDefault(){
      const createKey = this.authKey + '_event_holiday_sort';
      // let sortOrder = (this.order == 1) ? '1' : (this.order == 0) ? '0' : '2';
      APP_UTILITIES.setSortingOnSession(createKey, this.lastSorting.label, ''+this.lastSorting.order);
    }

    getSortingDefault(){
      const createKey = this.authKey + '_event_holiday_sort';
      let sortVal:string | null = '';
      if(APP_UTILITIES.getSortingOnSession(createKey)){
        sortVal = APP_UTILITIES.getSortingOnSession(createKey);
        const sortObj:{key: string; dir: string} = sortVal
          ? JSON.parse(sortVal)
          : {};
        if(this.isMobileView){
           
          this.getEventMobileMenuList.forEach((columnObj:any,columnObjIndex:number) => {
            /* istanbul ignore else */
            if(columnObj.label === sortObj.key){
              const columnSelected = this.getEventMobileMenuList[columnObjIndex];
              this.order = sortObj.dir == '1'
                ? 2
                : 1;
              columnObj.order = this.order;
              this.lastSorting = this.getEventMobileMenuList[columnObjIndex];
              this.lastSortingIndex = columnObjIndex;
              this.getSortedList(columnSelected , columnObjIndex, 'mobile');
            }
          });
        }
        else{
          this.getEventMenuList.forEach((columnObj:any,columnObjIndex:number) => {
            /* istanbul ignore else */ 
            if(columnObj.label === sortObj.key){
              const columnSelected = this.getEventMenuList[columnObjIndex];
              this.order = sortObj.dir == '1'
                ? 2
                : 1;
              columnObj.order = this.order;
              this.lastSorting = this.getEventMenuList[columnObjIndex];
              this.lastSortingIndex = columnObjIndex;

              this.getSortedList(columnSelected , columnObjIndex, 'desktop');
            }
          });
        }
      }
      else{
        this.setSortingDefault();
      }
    }

    sortHandling(){
      /* istanbul ignore else */
      if(this.mountedref == false){
        this.authKey = APP_UTILITIES.authTokenKeyToManage()
          ? APP_UTILITIES.authTokenKeyToManage()
          : '';
        if(this.authKey && this.events && this.events.length > 0){
          this.getSortingDefault();
        }
        else{
          this.mountedref=true;
        }
      }
    }

    editEventFromList(eventPayload:any){
      this.$emit('editDetailsEventHandler', eventPayload);
      this.hideKababdropdown=false;
    }

    showHideDrop(index: number) {
      if (index === this.id) {
        this.id = null;
      }
      else {
        this.id = index;
        this.hideKababdropdown=true;
        this.deleteConPopup = false;
      }
    }

    deleteEventConfirmation() {
      this.deleteConPopup = true;
      this.hideKababdropdown=false;
    }
      
    closeDeletePopup() {
      this.deleteConPopup = false;
      this.hideKababdropdown=false;
      this.id=-1;
    }

    deleteEvent(event:any) {
      this.deleteConPopup = false;
      if (event.isEvent) {
        deleteEvent(event).then((res: any) => {
          const ObjPlannerEvent = JSON.parse(JSON.stringify({ ...event, state: 'deleteEvent' }));
          this.$emit('eventData', ObjPlannerEvent);
          this.closeDeletePopup();
        });
      }
      else {
        deleteholidayEvent(event).then((res: any) => {
          const ObjPlannerEvent = JSON.parse(JSON.stringify({ ...event, state: 'deleteEvent' }));
          this.$emit('eventData', ObjPlannerEvent);
          this.closeDeletePopup();
        });
      }
    }
}