import { Vue, Component, Prop} from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from '@/constants/AppConst';

@Component({})
export default class PlannerPrintComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public roleId: number = 0;
    public getTasksMenuList: any =JSON.parse(JSON.stringify(APP_CONST.GET_TASK_MENUS));
    public programName:string = '';

    @Prop()
    tasksList!: any;

    @Prop()
    isLoading!: boolean;

    @Prop()
    showEventData!: boolean;

    @Prop()
    startDate!: any;

    @Prop()
    endDate!: any;

    @Prop()
    siteId!: number;

    @Prop()
    activeTab!: string;

    @Prop()
    selectedSiteName!: string;
    
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    formatDate(date: string) {
      return date
        ? APP_UTILITIES.formatDate(date, false, true)
        : '';
    }

    getActiveTab(activeTab: string) {
      let activeTabLabel: string = '';
      switch(activeTab) {
        case 'myTasks':
          activeTabLabel = 'My Tasks';
          break;
        case 'allTasks':
          activeTabLabel = 'All Tasks';
          break;
        case 'events':
          activeTabLabel = 'Events';
          break;
        default:
          break;
      }
      return activeTabLabel;
    }

    created() {
      this.programName = APP_UTILITIES.getCookie('progName') || '';
    }

    mounted() {
      const {roleId} = APP_UTILITIES.coreids();
      this.roleId = roleId;
    }

    updated() {
      this.programName = APP_UTILITIES.getCookie('progName') || '';
    }
}