import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import Events from '../../components/Events/Events.vue';

@Component({
  components: {
    'operate-events': Events
  }
})

export default class modal extends Vue {
    private objScreenText: ScreenText = new ScreenText();
   
      
    @Prop()
    eventMode!:string;
   
    @Prop()
    editEventPayload!:{}; 
    @Prop()
    isMobileView!:boolean;
    @Prop()
    userRoleAccessToButton!:Array<number>;
    @Prop()
    userRoleId!:number;
    
    @Prop()
    viewEventId!:number;

    @Prop()
    typeOfView!:string;
    
    @Prop()
    toggleListView!:boolean;

    @Prop()
    isSingleEventDownloading!:boolean;

    @Prop()
    siteDataResponse!:any;

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    editEvent(){
      this.$emit('editEvent');
    }

    eventData(data:any){
      this.$emit('eventData', data);
    }

    noActionPointer(pointer:boolean){
      this.$emit('noActionPointer', pointer); 
    }

    downloadSingleEvent(e:any,plannerEventObj:object){
      e.preventDefault();
      this.$emit('downloadSingleEvent',e,plannerEventObj);
      
        
    }
    
    addedSiteList(sites:string){
      this.$emit('addedSiteList',sites);
    }

}