import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import DisplayColumnFilter from '../displayColumnFilter/DisplayColumnFilter.vue';
import table from '@/store/modules/table';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { SmartTableRow } from '@/Model/model';


@Component({
  components: {
    'display-columns-dropdown': DisplayColumnFilter
  }
})
export default class FixedTableComponent extends Vue {
  public showSettings: boolean = false;
  public selectedTableRecords: SmartTableRow[] = [];
  public nonSelectedData: any = [];
  public isSameUser: boolean = false;
  public userId: string | null = '';
  public drill: boolean = false;
  public highestRole: number = JSON.parse(JSON.stringify(APP_UTILITIES.getCookie('highest_role')));
  public sessionAdminId: number = APP_CONST.SESSION_ADMIN_ROLE_ID;
  private isMobileForAPiCall: boolean = false;
  public tabKey: boolean = false;
  public enterKey: boolean = false;
  public hoverIndex: number = -1;
  private previouslySelectedRadioInput: any;

   @Prop({ default: false })
   showEditIcon!: boolean;

  @Prop()
  headers!: [];

  @Prop()
  tableData!: [];

  @Prop()
  allListData!: any;

  @Prop()
  displayColumn!: any;

  @Prop()
  componentName!: string;

  @Prop({ default: false })
  isSortingSearching!: boolean;

  @Prop({ default: true })
  showCheckBox!: boolean;

  @Prop({ default: false })
  limitOneCheckbox!: boolean;

  @Prop({ default: function () {
    return [];
  }})
  searchLists!: { header: string; values: any[] }[];

  @Prop()
  total_items!: 0;

  @Prop()
  payload!: any;

  @Prop({ default: 'blue' })
  theme!: string;

  /**
   * This property changes how the select all checkbox works;
   * instead of selecting all records from all pages, it will only consider the records on the current page.
   */
  @Prop({ default: false })
  limitSelectAllToCurrentPage!: boolean;

  /* istanbul ignore next */
  get allUnSelectData() {
    return table.allUnSelectData;
  }

  /* istanbul ignore next */
  get allDataSelected() {
    if (table.allDataSelected) {
      this.selectedTableRecords = table.allDataSelected;
    }
    return table.allDataSelected;
  }

  /* istanbul ignore next */
  get fewCheckedData() {
    return table.fewCheckedData;
  }

  @Watch('allDataSelected', { deep: true, immediate: true })
  allDataSelecteds(val: any) {
    this.selectedTableRecords = val;
    if (val.length === 0 && this.previouslySelectedRadioInput) {
      this.clearRadioInput(this.previouslySelectedRadioInput);
    }
  }

  /* istanbul ignore next */
  get isSelectAll() {
    if (table.allSelect) {
      const totalItems = this.componentName == 'user-table'
        ? this.userId
          ? this.total_items - 1
          : this.total_items
        : this.total_items;
      if (this.allUnSelectData.length === totalItems) {
        return false;
      }
      return true;
    }
    return this.selectedTableRecords.length > 0;
  }

  /**
   * Gets the current page selection status.
   * Used if the prop limitSelectAllToCurrentPage is set to true.
   * @returns An object containing the current page selection status.
   */
  get currentPageSelection(): { allSelected: boolean; anySelected: boolean } {
    if (this.limitSelectAllToCurrentPage) {
      let allSelected = true;
      let anySelected = false;

      for (const tableRow of this.tableData as SmartTableRow[]) {
        if (tableRow.selected) {
          anySelected = true;
        }
        else {
          allSelected = false;
        }
      }

      return { allSelected, anySelected };
    }

    return { allSelected: false, anySelected: false };
  }

  /**
   * Gets the classes for the select all checkbox.
   * The classes are determined based on the current rows selection.
   * @returns An array of strings representing the classes for the select all checkbox.
   */
  get selectAllCheckboxClass(): (string | false)[] {
    const { allSelected, anySelected } = this.currentPageSelection;
    let checkboxSelectAllClasses: (string | false)[] = [];

    const checkboxClasses = [
      'uk-checkbox remember-checkbox pd0 align',
      this.componentName == 'user-table' && this.highestRole == this.sessionAdminId && 'user-table-checkbox',
      !this.tableData.length && 'disabled'
    ];

    if (this.limitSelectAllToCurrentPage) {
      checkboxSelectAllClasses = [
        allSelected && 'darkCheckBox',
        !allSelected && anySelected && 'select-action-checkbox'
      ];
    }
    else {
      checkboxSelectAllClasses = [
        this.isSelectAll && 'darkCheckBox',
        this.fewCheckedData,
      ];
    }

    return [...checkboxClasses, ...checkboxSelectAllClasses];
  }

  /* istanbul ignore next */
  get disableCheckbox() {
    const userRoles: any = APP_UTILITIES.getCookie('highest_role');
    if (userRoles != APP_CONST.ROLE_TYPE_BX_ADMIN && userRoles != APP_CONST.ROLE_TYPE_SUPER_ADMIN) {
      this.userId = this.componentName == 'user-table' && APP_UTILITIES.getCookie('user_id')
        ? APP_UTILITIES.getCookie('user_id')
        : '';
    }
    return this.userId;
  }

  get sortIcon() {
    if (this.payload.sortField == this.payload.searchField || this.payload.sortField == this.payload.SearchField) {
      return this.payload.sortDir == 1
        ? 'sort-a-to-z-white'
        : 'sort-z-to-a-white';
    }
    else {
      return this.payload.sortDir == 1
        ? `sort-a-to-z-${this.theme}`
        : `sort-z-to-a-${this.theme}`;
    }
  }

  /* istanbul ignore next */
  mounted() {
    if (APP_UTILITIES.getCookie(APP_CONST.SUPER_USER_DRILLDOWN)) {
      const drilldrown: any = APP_UTILITIES.getCookie(APP_CONST.SUPER_USER_DRILLDOWN);
      this.drill = JSON.parse(JSON.stringify(drilldrown));
    }
    const element = document.getElementsByClassName('fixed-left-column-freezed')[0];
    const parentElement = document.getElementById('padding');
    if (parentElement) {
      const stickyLeftDiv = document.getElementById('stickyLeftDiv');
      parentElement.style.paddingLeft = !stickyLeftDiv
        ? element.scrollWidth - 1 + 'px'
        : element.scrollWidth + 'px';
      if (stickyLeftDiv) {
        stickyLeftDiv.style.width = parentElement.style.paddingLeft;
      }
    }
    if (table.allDataSelected) {
      this.selectedTableRecords = table.allDataSelected;
    }
    if (table.unSelectData) {
      this.nonSelectedData = table.unSelectData;
    }
  }

  navigateToPage(accountData: any) {
    this.$emit('navigateToPage', accountData);
  }
  callHover(index: number) {
    this.hoverIndex = index;
    this.$emit('rowHovered', index);
  }
  callHoverOut() {
    this.hoverIndex = -1;
    this.$emit('rowHovered', -1); // Reset hover state
  }

  filterColumns(displayColumn: any) {
    this.$emit('filterColumns', displayColumn);
    this.$emit('calculateTableWidth');
  }

  filterDisplayColumn(column: any) {
    this.$emit('filterDisplayColumn', column);
  }

  openSortingDropdown(header: any, $event: any) {
    /* istanbul ignore else */
    if (this.isSortingSearching) {
      const elem = <HTMLDivElement>document.getElementById(header.value);
      const pos = elem.getBoundingClientRect();
      const x: any = pos.left + 16;
      const y = pos.top + APP_CONST.FOURTY_FIVE;
      this.$emit('openSortingDropdown', header, { x, y });
    }
  }

  handleSortingArrows(header: any) {
    this.headers.forEach((originalHeader: any) => {
      /* istanbul ignore else */
      if (originalHeader.value === header.value) {
        originalHeader.isOpen = !originalHeader.isOpen;
      }
    });
  }

  selectAllRecords($event: any) {
    const selected = $event.target.checked;

    if (this.limitSelectAllToCurrentPage) {
      this.selectAllRecordsOnCurrentPage(selected);
      return;
    }

    if (selected) {
      table.mutateSelectAll(true);
      table.mutateAllSelectedData([]);
      table.mutateUnSelectedData([]);
      this.nonSelectedData = [];
    }
    else {
      table.mutateSelectAll(false);
      table.mutateAllSelectedData([]);
      table.mutateUnSelectedData([]);
      this.nonSelectedData = [];
    }
    this.selectedTableRecords = [];
    this.tableData && this.tableData.forEach((data: any) => {
      data.selected = selected;
      /* istanbul ignore else */
      if (this.componentName == 'user-table' && data && data.userId == this.userId) {
        data.selected = false;
        /* istanbul ignore else */
      }
    });
    this.halfDataChecked();
    this.$emit('selectionChanged');
  }

  selectParticularRecord(data: any) {
    data.selected = !data.selected;

    if (this.limitSelectAllToCurrentPage) {
      this.selectParticularRecordOnCurrentPage(data);
      return;
    }

    if (table.allDataSelected) {
      this.selectedTableRecords = table.allDataSelected;
    }
    if (table.unSelectData) {
      this.nonSelectedData = table.unSelectData;
    }
    if (table.allSelect) {
      const index = this.nonSelectedData.findIndex((element: any) => this.componentName == 'account-table'
        ? element.accountId == data.accountId
        : this.componentName == 'programs-table'
          ? element.programId == data.programId
          : this.componentName == 'sites-table'
            ? element.siteId == data.siteId
            : this.componentName == 'user-table'
              ? element.userId == data.userId
              : this.componentName === 'site-locations-table'
                ? element.id === data.id
                : '');
      if (index == -1) {
        this.nonSelectedData.push(data);
      }
      else {
        this.nonSelectedData.splice(index, 1);
      }
    }
    else {
      const index = this.selectedTableRecords.findIndex((element: any) => this.componentName == 'account-table'
        ? element.accountId == data.accountId
        : this.componentName == 'programs-table'
          ? element.programId == data.programId
          : this.componentName == 'sites-table'
            ? element.siteId == data.siteId
            : this.componentName == 'user-table'
              ? element.userId == data.userId
              : this.componentName === 'site-locations-table'
                ? element.id === data.id
                : '');
      if (index == -1) {
        if (this.limitOneCheckbox) {
          if (this.previouslySelectedRadioInput && this.previouslySelectedRadioInput !== data) {
            this.previouslySelectedRadioInput.selected = false;
          }
          this.selectedTableRecords.splice(0, this.selectedTableRecords.length);
        }
        this.selectedTableRecords.push(data);
      }
      else {
        if (this.limitOneCheckbox) {
          this.clearRadioInput(data);
        }
        this.selectedTableRecords.splice(index, 1);
      }
    }
    if (this.limitOneCheckbox) {
      this.previouslySelectedRadioInput = data;
    }
    this.halfDataChecked();
    this.$emit('selectionChanged', data);
  }

  /**
   * Selects or deselects all records on the current page.
   * @param selected - A boolean indicating whether to select or deselect the records.
   */
  selectAllRecordsOnCurrentPage(selected: boolean): void {
    this.tableData && this.tableData.forEach((row: SmartTableRow) => {
      row.selected = selected;

      if (selected) {
        this.addRowToSelectedRecords(row);
      }
      else {
        this.removeRowFromSelectedRecords(row.rowKey);
      }
    });

    table.mutateAllSelectedData(this.selectedTableRecords);
    this.$emit('selectionChanged');
  }

  /**
   * Selects or deselects a particular record on the current page.
   * @param row - The row to be selected or deselected.
   */
  selectParticularRecordOnCurrentPage(row: SmartTableRow): void {
    const selected = row.selected;

    if (selected) {
      this.addRowToSelectedRecords(row);
    }
    else {
      this.removeRowFromSelectedRecords(row.rowKey);
    }

    table.mutateAllSelectedData(this.selectedTableRecords);
    this.$emit('selectionChanged', row);
  }

  /**
   * Adds a row to the selected records.
   * @param row - The row to be added.
   */
  addRowToSelectedRecords(row: SmartTableRow): void {
    this.selectedTableRecords.push(row);
  }

  /**
   * Removes a row from the selected table records based on the provided row key.
   * @param {string} rowKey - The key of the row to be removed.
   */
  removeRowFromSelectedRecords(rowKey: string): void {
    const rowIndex = this.selectedTableRecords.findIndex((selectedRow: SmartTableRow) => selectedRow.rowKey === rowKey);
    // If the prop limitSelectAllToCurrentPage is set to true,
    // it could happen that the row is not found in the selectedTableRecords.
    // As the row could be in a different page.
    if (rowIndex > -1) {
      this.selectedTableRecords.splice(rowIndex, 1);
    }
  }

  halfDataChecked() {
    if (table.allSelect) {
      table.mutateUnSelectedData(this.nonSelectedData);
      if (!this.nonSelectedData.length) {
        const active: any = this.allListData && this.allListData.filter((element: any) => {
          return element.status == 1;
        });
        const deactive: any = this.allListData && this.allListData.filter((element: any) => {
          return element.status == 2;
        });
        if (active && active.length && deactive && deactive.length) {
          table.mutateSelectStatus('All');
        }
        else if (active && active.length) {
          table.mutateSelectStatus('Disable');
        }
        else if (deactive && deactive.length) {
          table.mutateSelectStatus('Enable');
        }
        else {
          table.mutateSelectStatus('');
        }
      }
      else {
        const addData: any = this.allListData && this.allListData.filter((n: any) => !this.nonSelectedData.some((n2: any) => this.componentName == 'account-table'
          ? n.accountId == n2.accountId
          : this.componentName == 'programs-table'
            ? n.programId == n2.programId
            : ''));
        const active: any = addData && addData.filter((element: any) => {
          return element.status == 1;
        });
        const deactive: any = addData && addData.filter((element: any) => {
          return element.status == 2;
        });
        if (active && active.length && deactive && deactive.length) {
          table.mutateSelectStatus('All');
        }
        else if (active && active.length) {
          table.mutateSelectStatus('Disable');
        }
        else if (deactive && deactive.length) {
          table.mutateSelectStatus('Enable');
        }
        else {
          table.mutateSelectStatus('');
        }
      }
      const totalItems = this.componentName == 'user-table'
        ? this.userId
          ? this.total_items - 1
          : this.total_items
        : this.total_items;
      if (this.allUnSelectData.length && this.allUnSelectData.length !== totalItems) {
        table.mutateFewCheckData('select-action-checkbox');
      }
      else {
        table.mutateFewCheckData('');
      }
      if (this.nonSelectedData.length === this.total_items) {
        table.mutateSelectAll(false);
        table.mutateAllSelectedData([]);
        table.mutateUnSelectedData([]);
        const element = <HTMLInputElement>document.getElementById('all-data-checkbox');
        if (element) {
          element.checked = false;
        }
      }
    }
    else {
      table.mutateAllSelectedData(this.selectedTableRecords);
      const active: any = this.selectedTableRecords.filter((element: any) => {
        return element.status == 1;
      });
      const deactive: any = this.selectedTableRecords.filter((element: any) => {
        return element.status == 2;
      });
      if (active && active.length && deactive && deactive.length) {
        table.mutateSelectStatus('All');
      }
      else if (active && active.length) {
        table.mutateSelectStatus('Disable');
      }
      else if (deactive && deactive.length) {
        table.mutateSelectStatus('Enable');
      }
      else {
        table.mutateSelectStatus('');
      }
      /* istanbul ignore else */
      const totalItems = this.componentName == 'user-table'
        ? this.userId
          ? this.total_items - 1
          : this.total_items
        : this.total_items;
      if (this.allDataSelected && this.allDataSelected.length && this.allDataSelected.length !== totalItems) {
        table.mutateFewCheckData('select-action-checkbox');
      }
      else {
        table.mutateFewCheckData('');
      }
    }
  }

  closeGearIcon(event?: any) {
    this.showSettings = false;
    event.currentTarget.focus();
  }

  openGearDropdown(event: any) {
    event.preventDefault();
    if (event.keyCode == 40 || event.keyCode == 38 || event.keyCode == 32 || event.keyCode == 13) {
      this.showSettings = true;
    }
    const id: any = document.getElementById('display-all');
    if (id) {
      setTimeout(() => {
        id.focus();
      }, 20);
    }
  }

  clearRadioInput(data: any) {
    const clickedRadioInput = this.$refs[`radioInput${data.id}`];
    if (Array.isArray(clickedRadioInput)) {
      const castObject = clickedRadioInput[0] as HTMLInputElement;
      castObject.checked = false;
    }
  }

  tab(val: boolean) {
    this.tabKey = true;
    this.showSettings = false;
  }

  enter(val: boolean) {
    this.enterKey = true;
    this.showSettings = false;
  }
}