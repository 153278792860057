import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import UIkit from 'uikit';
import APP_UTILITIES from '@/utilities/commonFunctions';

@Component({})

export default class TeamRosterViewDetails extends Vue {

  @Prop()
  teamrosterresponse!:any;

  @Prop()
  siteSessionData!:any;


    private objScreenText: ScreenText = new ScreenText();
    public pointerEventNone:boolean = true;

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    close(){
      if (UIkit.offcanvas('#team-roster-view-details')) {
        this.pointerEventNone = false;
        UIkit.offcanvas('#team-roster-view-details').hide();
        this.$emit('noActionPointer', this.pointerEventNone);
      }
    }

    formatNumber(phoneNumber: string){
      return phoneNumber && APP_UTILITIES.formatNumber(phoneNumber);
    }
}