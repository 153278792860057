















































































































import Events from '@/components/plannerComponent/components/PrintDetailsEvent/EventsPrint';
export default Events;
