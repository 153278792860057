import store from '@/store';
import {
  getModule,
  Module,
  VuexModule,
  Action,
  Mutation
} from 'vuex-module-decorators';
import { ProgramQuery } from '@/Model/model';
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from '@/constants/AppConst';

@Module({
  namespaced: true,
  name: 'tableStore',
  store,
  dynamic: true
})

class TableModule extends VuexModule {
  programQuery: ProgramQuery = {} as ProgramQuery;
  public filteredObj: any = APP_CONST.ACCOUNTS_HEADER.MOVABLE;
  public accountSettingsObj: any = {};
  public allSelectedData:any = [];
  public fewChecked:string = '';
  public accountResizeData:any = [];
  public resizableArray: any[] = [];
  public resizeObj: any[] = [];
  public authKey: string = '';
  public selectAll:boolean = false;
  public unSelectData:any = [];
  public commonSearchInputSave: any = [{key: '', value: ''}];
  public selectStatus:string = '';

  get allSelect(){
    return this.selectAll;
  }

  get allUnSelectData(){
    return this.unSelectData;
  }

  get allDataSelected(){
    return this.allSelectedData;
  }

  get fewCheckedData(){
    return this.fewChecked;
  }

  get programsQuery() {
    return this.programQuery;
  }

  get filteredObject() {
    return this.filteredObj;
  }

  get accountSettingsObject() {
    return this.accountSettingsObj;
  }

  get accountResizesData() {
    return this.accountResizeData;
  }

  get selectedStatus(){
    return this.selectStatus;
  }

  @Mutation
  mutateSelectStatus(selectStatus:any){
    this.selectStatus = selectStatus;
  }

  @Mutation
  mutateUnSelectedData(unSelectData:any){
    this.unSelectData = unSelectData;
  }

  @Mutation
  mutateSelectAll(selectAll:boolean){
    this.selectAll = selectAll;
  }

  @Mutation
  mutateFewCheckData(fewChecked:any){
    this.fewChecked = fewChecked;
  }

  @Mutation
  mutateAllSelectedData(allSelectedData:any){
    this.allSelectedData = allSelectedData;
  }

  @Mutation
  mutateSettingsData(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : '';
    this.accountSettingsObj = data;
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-AccountsSettingsData`, this.accountSettingsObj);
  }

  @Mutation
  mutateResizeData(obj:any){
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : '';
    if (this.resizeObj.length) {
      this.resizableArray = this.resizeObj;
    }
    const keyIndex = this.resizableArray.findIndex((e: { id: any }) => e.id == obj.column.id);
    if (keyIndex > -1) {
      this.resizableArray[keyIndex] = { id: obj.column.id, maxWidth: obj.maxWidth, minWidth: obj.minWidth };
    }
    else {
      this.resizableArray.push({ id: obj.column.id, maxWidth: obj.maxWidth, minWidth: obj.minWidth });
    }
    this.resizeObj = JSON.parse(JSON.stringify(this.resizableArray));
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-AccountsResizeData`, this.resizeObj);
  }

  @Mutation
  mutateResizeObject(accountResizeData:any){
    this.resizeObj = [];
    this.resizeObj = accountResizeData;
    this.accountResizeData = accountResizeData;
  }

  @Mutation
  mutateFilteredObject(display: any) {
    this.filteredObj = display;
  }

  @Mutation
  public setQuery(query: ProgramQuery) {
    this.programQuery = query;
  }

  @Action({ commit: 'setQuery' })
  QueryForProgram(query: ProgramQuery) {
    return query;
  }

  @Mutation
  updatecommonSearchArray(input: any){
    this.commonSearchInputSave = input;
  }

  get searchInputValues(){
    return this.commonSearchInputSave;
  }
}

export default getModule(TableModule);
