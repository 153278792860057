























import { PopupWrapperComponent } from '@/commoncomponents/popupwrapper/PopupWrapperComponent';
export default PopupWrapperComponent;
