import { Vue, Component, Prop} from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import { VueEditor } from 'vue2-editor';
import APP_CONST from '@/constants/AppConst';
import { ITaskDetailDto } from '@/services/program/plannerService';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import { addTask, editTask, deleteTask, updateTaskStatus, addFile, copyTask, addComment } from '@/services/program/plannerService';
import APP_UTILITIES from '@/utilities/commonFunctions';
import plannerStore from '@/store/modules/plannerStore';
import programConfigure from '@/store/modules/programConfigure';

@Component({
  components: {
    'datepicker': DatepickerComponent,
    'notes-editor': VueEditor
  },
  filters: {
    initialLetter: function (value: string) {
      if (!value) {
        return '';
      }
      value = value.toString();
      return value.charAt(0).toUpperCase();
    }
  }
})
export default class TaskPrintComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    sections = APP_CONST.SECTIONS;
    validatedFields = JSON.parse(JSON.stringify(APP_CONST.TASK_FORM));
    dateType = '';
    validationIndicator = { entireFieldsEntered: false, wrongEndDate: false};
    searchOwner: string = '';
    searchCollaborator: string = '';
    taskUserIndex: number = -1;
    collaboratorDrop: boolean = false;
    ownerDrop: boolean = false;
    status : string[] = ['Active', 'Done', 'Skip'];
    status_dropdown : {id: number; name: string; img: string}[] = [{id: 1, name: 'Active', img: 'active-icon.svg'}, {id:2, name:'Done', img: 'done-status.svg'}, {id:3, name:'Skip', img: 'skip-icon.svg'}];
    confirmationBox: boolean = false;
    statusBox: boolean = false;
    statusBoxMob = false;
    closeInfo: boolean = false;
    selectedFile: any = '';
    largeFileError: boolean = false;
    unsupportedFileError: boolean = false;
    hoverText: string = '';
    hovering: boolean = false;
    hoverIndex: number = -1;
    userId: number = 0;
    disableAddFileLink: boolean = false;
    disableCopyBtn: boolean = false;
    copyTaskRevert: boolean = false;
    copiedTaskId: number = -1;
    ElementRightZero = false;
    isFileUploadLinkActive: boolean = false;
    whatCanIUploadTextId = APP_CONST.WHAT_CAN_UPLOAD_TEXT_ID;
    public customToolbar:any = APP_CONST.CUSTOM_TOOLBAR;
    public comment: string = '';
    public prevDate: string = '';
    mode:string = 'view';
    programName:string = '';
    siteName:string = '';

    @Prop()
    taskDetails!: ITaskDetailDto;

    @Prop()
    selectedSiteName!: string;

    @Prop()
    printTaskDiscussionList!: any;

    @Prop()
    lastPageActive!: boolean;

    @Prop()
    headerNotVisible!: boolean;

    @Prop()
    ownerList!:  {id: number; profilePicture: [{thumbnailUrl: string; imageUrl: string}]; firstName: string; lastName: string; email: string}[];

    @Prop()
    isMobileView!:boolean;
    trim(x: string) {
      return (x && x.replace(/^\s+|\s+$/gm,'')) || '';
    }
      
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    filterOwner(item: {id: 0;taskId: 0;userId: 0;isOwner: boolean}[]) {
      return item.filter((el) => el.isOwner == true);
    }

    get siteId() {
      return plannerStore.getSiteId;
    }

    get sites() {        
      return plannerStore.sitesList;
    }

    get taskName(){
      return this.taskDetails
        ?this.taskDetails.name.split(' ').join('&nbsp;')
        :'';
    }

    created() {
      this.programName = APP_UTILITIES.getCookie('progName') || '';
    }

    mounted() {
      const userId: string | null = APP_UTILITIES.getCookie('user_id');        
      this.userId = userId && parseInt(userId) || 0;
      const {roleId, siteId} = APP_UTILITIES.coreids();
      if([APP_CONST.ROLE_TYPE_SESSION_ADMIN, APP_CONST.ROLE_TYPE_STAFF].includes(roleId)) {
        if(siteId && this.sites.length) {
          const selectedSite:any = this.sites.find((site:any)=>site.siteId == siteId);
          if(selectedSite){
            this.siteName = selectedSite.siteName;

          }
        }
            
      }

    }

    filterCollaborator(item: {id: 0;taskId: 0;userId: 0;isOwner: boolean}[]) {
      return item.filter((el) => el.isOwner == false);
    }

    addUpTask(payload: ITaskDetailDto) {
      const {programId, siteId} = APP_UTILITIES.coreids();
      const ValidateObj = this.validatedFields;
      const checkValidation = APP_UTILITIES.requiredFields(payload, ValidateObj);
      this.validatedFields = { ...checkValidation.validateData };
      if (checkValidation.isFormInvalid) {
        this.validationIndicator.entireFieldsEntered = checkValidation.isFormInvalid;
        return; 
      }
      if(this.validationIndicator.wrongEndDate) {
        return;
      }
      payload.siteId = siteId
        ? siteId
        : this.siteId;
      addTask(payload).then((response) => {
        if (response.status === APP_CONST.RESPONSE_200) {
          this.close();
          const getTasksParam = {programId: programId, productId: programConfigure.productId, siteId: siteId
            ? siteId
            : this.siteId};
          plannerStore.tasks(getTasksParam);
          setTimeout(() => {
            this.$emit('updateSorting');
          }, 500);
        }
        else if(response.status === APP_CONST.RESPONSE_400) {
          this.close();
        }
      }, () => {
        this.close();
      });
    }

    modifyTask(payload: ITaskDetailDto) {
      const {programId} = APP_UTILITIES.coreids();
      payload.programId = programId;
      payload.siteId = this.siteId;
      const ValidateObj = this.validatedFields;
      const checkValidation = APP_UTILITIES.requiredFields(payload, ValidateObj);
      this.validatedFields = { ...checkValidation.validateData };
      if (checkValidation.isFormInvalid) {
        this.validationIndicator.entireFieldsEntered = checkValidation.isFormInvalid;
        return; 
      }
      if(this.validationIndicator.wrongEndDate) {
        return;
      }

      editTask(payload).then((response) => {
        if (response.status === APP_CONST.RESPONSE_200) {
          this.close();
          const getTasksParam = {programId: programId, productId: programConfigure.productId, siteId: this.siteId};
          plannerStore.tasks(getTasksParam);
          setTimeout(() => {
            this.$emit('updateSorting');
          }, 500);
        }
      });
    }

    removeTask(id: number) {
      deleteTask(id).then((response) => {
        if(response.status === APP_CONST.RESPONSE_200) {
          if (this.taskDetails.id === id) {
            this.close();
          }
          else {
            this.closeCopyTaskRevertPop();
          }
          this.disableCopyBtn = false;
          const {programId} = APP_UTILITIES.coreids();
          const getTasksParam = {programId: programId, productId: programConfigure.productId, siteId: this.siteId};
          plannerStore.tasks(getTasksParam);
          setTimeout(() => {
            this.$emit('updateSorting');
          }, 500);
        }
      });
    }

    checkNames(key: 'name'|'startDate'|'endDate', model: string) {
      if(this.validatedFields.hasOwnProperty(key)) {
        this.validatedFields[key].error = model
          ? false
          : true;
      }
    }

    checkForCorrectDate(key: 'name'|'startDate'|'endDate', model: string) {
      this.dateType = '';
      this.validationIndicator.wrongEndDate = false;
      const startDate = new Date(this.taskDetails.startDate).toUTCString();
      const endDate = new Date(this.taskDetails.endDate).toUTCString();
      this.checkNames(key, model);
      if (startDate && endDate && Date.parse(endDate) < Date.parse(startDate)) {
        setTimeout(() => {
          this.taskDetails.endDate = ''; 
        });
        this.validationIndicator.wrongEndDate = true;
      }
    }


    switchNavigation(index: number, id:string) {
      this.sections.forEach((el, el_index) => {
        el.active = el_index === index
          ? true
          : false;
      });
      const tab = this.$refs as any;
      tab && tab[id]&&tab[id].focus();
    }

    handleScroll(ev: any) {
      const ele = document.getElementById('right-panel-section');
      const offsetTop = ele && ele.offsetTop;
      const positive =  offsetTop && Math.abs(offsetTop) || 0;
      const element_width = ele && ele.clientHeight || 0;
      const divided = positive / element_width;
      const round = Math.round(divided);
      const current_element = ele && ele.children[round - 1];
      const id = current_element && current_element.id;
      this.sections.forEach((elem) => {
        if(id === elem.id) {
          elem.active = true;
        }
        else {
          elem.active = false;
        }
      });
    }

    getPic(type: string, base64: string) {
      let profileImageSrc = '';
      if (type !== '' || base64 !== '') {
        profileImageSrc = 'data:' + type + ';' + 'base64,' + base64;
      } 
      return profileImageSrc;
    }

    filterOwners(ownerList: {firstName: string; lastName: string}[], search: string) {
      return ownerList.filter((el: {firstName: string; lastName: string})=> {
        return `${el.firstName + el.lastName}`.toLowerCase().includes(search.toLowerCase());
      });
    }

    assignOwner(owner: {id: number; firstName: string; lastName: string}, userId: number, isOwner: boolean) {
      const usersList = this.ownerList.length
        ? this.ownerList
        : [];
      const taskUsers = JSON.parse(JSON.stringify(this.taskDetails.taskUsers));
      const index = usersList.findIndex((userDt: {id: number; profilePicture: [{thumbnailUrl: string; imageUrl: string}]; firstName: string; lastName: string}) => userDt.id === owner.id);
      const taskUserIndex = taskUsers.findIndex((user: {id: number;taskId: number; userId: number; isOwner: boolean; profilePicture: [{thumbnailUrl: string; imageUrl: string}]; firstName: string; lastName: string; email: string}) => (user.userId === userId) && (user.isOwner === isOwner));
      if((taskUserIndex >= 0) && (index >= 0)) {
        taskUsers[taskUserIndex].profilePicture = usersList[index].profilePicture;
        taskUsers[taskUserIndex].firstName = usersList[index].firstName;
        taskUsers[taskUserIndex].lastName = usersList[index].lastName;
        taskUsers[taskUserIndex].email = usersList[index].email;
        taskUsers[taskUserIndex].userId = owner.id;
        taskUsers[taskUserIndex].isOwner = isOwner;
        this.taskDetails.taskUsers = JSON.parse(JSON.stringify(taskUsers));
        this.taskUserIndex = -1;
        this.searchOwner = '';
      }
    }

    assignFirstOwner(owner: {id: number; firstName: string; lastName: string}) {
      const usersList = this.ownerList.length
        ? this.ownerList
        : [];
      const index = usersList.findIndex((userDt: {id: number; profilePicture: [{thumbnailUrl: string; imageUrl: string}]; firstName: string; lastName: string}) => userDt.id === owner.id);
      if(index >= 0) {
        this.taskDetails.taskUsers.push({profilePicture: usersList[index].profilePicture, firstName: usersList[index].firstName, 
          lastName: usersList[index].lastName, email: usersList[index].email, userId: usersList[index].id, isOwner: true, id: 0, taskId: 0});
        this.ownerDrop = false;
      }
    }

    addColaborator(collaborator: {id: number; firstName: string; lastName: string}, userId: number) {
      const taskIndex = this.taskDetails.taskUsers.findIndex(el => (el.userId === collaborator.id) && el.isOwner === false);
      if((taskIndex < 0)  && (collaborator.id > 0)) {
        const usersList = this.ownerList.length
          ? this.ownerList
          : [];
        const index = usersList.findIndex((userDt: {id: number; profilePicture: [{thumbnailUrl: string; imageUrl: string}]; firstName: string; lastName: string}) => userDt.id === collaborator.id);
        if(index >= 0) {
          const newTask = {id: 0, taskId: 0, userId: collaborator.id, isOwner: false, profilePicture: usersList[index].profilePicture, 
            firstName: usersList[index].firstName, lastName: usersList[index].lastName, email: usersList[index].email};
          this.taskDetails.taskUsers.push(newTask);
          this.collaboratorDrop = false;
        }
      }
      else {
        this.collaboratorDrop = false;
      }
      this.$nextTick(() => {
        this.setElementPosition();
      });
    }

    deleteColaborator(userId: number) {
      const collaboratorIndex =  this.taskDetails.taskUsers.findIndex(el => (el.userId === userId) && el.isOwner === false);
      if(collaboratorIndex >= 0) {
        this.taskDetails.taskUsers.splice(collaboratorIndex, 1);
      }
      this.$nextTick(() => {
        this.setElementPosition();
      });
    }

    getColorCode(email:string){
      return APP_UTILITIES.getColorCode(email);
    }

    monthddyyyy(date: string) {
      if(this.isTomorrow(date)) {
        return `Tomorrow (${APP_UTILITIES.formatDate(date, false, true)})`;
      }
      else {
        return date
          ?  APP_UTILITIES.formatDate(date, false, true)
          : '- -';
      }
    }

    isTomorrow(date: string) {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const dateTocheck = new Date(date);
      return dateTocheck.getDate() === tomorrow.getDate() && dateTocheck.getMonth() === tomorrow.getMonth() && dateTocheck.getFullYear() === tomorrow.getFullYear();
    }

    changeMode(mode: string) {
      this.$emit('mode', {mode:mode});
    }

    selectTaskStatus(status: number, id: number){
      const payload = { status, id};
      updateTaskStatus(payload).then((response) => {
        if (response.status === APP_CONST.RESPONSE_200) {
          this.taskDetails.status = status;
          this.statusBox = false;
          this.statusBoxMob = false;
          const {programId, siteId} = APP_UTILITIES.coreids();
          const getTasksParam = {programId: programId, productId: programConfigure.productId, siteId: siteId};
          plannerStore.tasks(getTasksParam);
        }
      });
    }

    close() {
      this.$emit('close', {name:'operate-task'});
      this.confirmationBox = false;
      this.searchOwner = '';
      this.searchCollaborator = '';
      this.statusBox = false;
      this.statusBoxMob = false;
      this.validationIndicator = { entireFieldsEntered: false, wrongEndDate: false};
      this.validatedFields = JSON.parse(JSON.stringify(APP_CONST.TASK_FORM));
      this.switchNavigation(0, 'right-panel-section');
      this.largeFileError= false;
      this.unsupportedFileError = false;
      this.copyTaskRevert = false;
      this.disableCopyBtn = false;
      this.copiedTaskId = -1;
      this.comment = '';
      this.prevDate = '';
      const {programId, siteId} = APP_UTILITIES.coreids();
      const getTasksParam = {programId: programId, productId: programConfigure.productId, siteId: siteId
        ? siteId
        : this.siteId};
      plannerStore.tasks(getTasksParam);
      const conent:any = document.querySelector('#mobile-tabs');
      conent.scrollLeft -= 300;
    }

    fileHandler(event: any) {
      this.selectedFile = event.target.files[0] || event.dataTransfer.files[0];
      const supportedFileTypes = [ 'image/gif', 'application/pdf', 'image/jpg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint'];
      if (this.selectedFile.size > 5000000) {
        this.unsupportedFileError = false;
        this.largeFileError = true;
      }
      else if (!(supportedFileTypes.includes(this.selectedFile.type))){
        this.largeFileError = false;
        this.unsupportedFileError = true;
      }
      else {
        this.largeFileError = false;
        this.unsupportedFileError = false;
        this.disableAddFileLink = true;
        this.appendTaskFiles(this.selectedFile);
      } 
    }

    clearPreviousInput(inputElement: any){
      inputElement.target.value = '';
    }

    appendTaskFiles(item: any) {
      if (this.taskDetails.taskFiles.length >= 5) {
        return;
      }
      else{
        const formData = new FormData();
        formData.append('formFile', item);
        const type = APP_CONST.TASK;
        addFile(type, formData).then((response) => {
          if (response.status === APP_CONST.RESPONSE_200) {
            item.id = response.data;
            this.taskDetails.taskFiles.push(item);
            this.taskDetails.fileIds.push(response.data);
            this.disableAddFileLink = false;
          }
          else {
            this.disableAddFileLink = false;
          }
        });
        this.selectedFile = '';
      }
    }

    removeFileById(fileId: number){
      const fileIdIndex = this.taskDetails.fileIds.findIndex(function(id){
        return id === fileId;
      });
      if (fileIdIndex !== -1) {
        this.taskDetails.fileIds.splice(fileIdIndex, 1);
        var index = this.taskDetails.taskFiles.findIndex(function(obj){
          return obj.id === fileId;
        });
        if (index !== -1) {
          this.taskDetails.taskFiles.splice(index, 1);
        }
      }
      else {
        var index = this.taskDetails.taskFiles.findIndex(function(obj){
          return obj.id === fileId;
        });
        if (index !== -1) {
          this.taskDetails.deletedFileIds.push(this.taskDetails.taskFiles[index].id);
          this.taskDetails.taskFiles.splice(index, 1);
        }
      }
    }

    checkForSelected(user:{id: number; profilePicture: [{thumbnailUrl: string; imageUrl: string}]; firstName: string; lastName: string; email: string}, check: boolean){
      const userIndex =  this.taskDetails.taskUsers.findIndex(el => (el.userId === user.id) && el.isOwner === check);
      if(userIndex >= 0) {
        return false;
      }
      else{
        return true;
      }
    }

    checkForOwner() {
      const userIndex = this.taskDetails.taskUsers.findIndex(el => el.isOwner === true);
      if(userIndex >= 0) {
        return false;
      }
      else{
        return true;
      }
    }

    /**
     * Profile tooltip
     * 
     * @param text 
     */
    showTooltip(text:string) {
      this.hoverText= text;
      this.hovering= true;
    }
    
    callHover(index:number, text: string, $event :any) {
      if($event.target.offsetWidth < $event.target.scrollWidth){
        this.hoverText= text;
        this.hovering= true;
        this.hoverIndex = index;
      }
    }

    callHoverOut() {
      this.hoverText= '';
      this.hovering= false;
      this.hoverIndex = -1;
    }

    filterDropdownStatus(){
      return this.status_dropdown.filter((el) => el.id !== this.taskDetails.status);
    }

    getPosition(el: any) {
      let xPos = 0;
      let yPos = 0;
       
      while (el) {
        if (el.tagName == 'BODY') {
          // deal with browser quirks with body/window/document and page scroll
          const xScroll = el.scrollLeft || document.documentElement.scrollLeft;
          const yScroll = el.scrollTop || document.documentElement.scrollTop;
       
          xPos += (el.offsetLeft - xScroll + el.clientLeft);
          yPos += (el.offsetTop - yScroll + el.clientTop);
        }
        else {
          // for all other non-BODY elements
          xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
          yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }
       
        el = el.offsetParent;
      }
      return {
        x: xPos,
        y: yPos
      };
    }

    setElementPosition() {
      const rightPanel = document.getElementById('right-panel-section');
      const panelWidth = (rightPanel && rightPanel.clientWidth) || 0;
      const windowWidth = (window.innerWidth > 0)
        ? window.innerWidth
        : screen.width;
      const screenWidth = windowWidth <= 768
        ? windowWidth
        : panelWidth;
      const myElement = document.querySelector('#collaborator'); 
      const position = this.getPosition(myElement);
      const diff = screenWidth - (position.x + 184);
      if(windowWidth <= 768) {
        if(diff < 120) {
          this.ElementRightZero = true;
        }
        else {
          this.ElementRightZero = false;
        }
      }
      else {
        if(diff < -184) {
          this.ElementRightZero = true;
        }
        else {
          this.ElementRightZero = false;
        }
      }
    }

    isOwnerOrCollaborator(){
      const userIndex = this.taskDetails.taskUsers.findIndex(el => el.userId === this.userId);
      if(userIndex >= 0) {
        return true;
      }
      else{
        return false;
      }
    }

    copyCurrentTask(id: number){
      this.disableCopyBtn = true;
      const { programId } = APP_UTILITIES.coreids();
      const payload = { taskId: id,
        siteId: this.siteId,
        programId: programId
      };
      copyTask(payload).then((response) => {
        if (response.status === APP_CONST.RESPONSE_200) {
          this.copiedTaskId = response.data;
          this.copyTaskRevert = true;
          const getTasksParam = {programId: programId, productId: programConfigure.productId, siteId: this.siteId};
          plannerStore.tasks(getTasksParam);
        }
      });
    }

    closeCopyTaskRevertPop(){
      this.copyTaskRevert = false;
      this.disableCopyBtn = false;
      this.copiedTaskId = -1;
    }

    submitComment(){
      if (this.comment === '') {
        return;
      }
      const comment = {
        id: 0,
        taskId: 0,
        comment: this.comment,
        userId: this.userId,
        createdOn: new Date()
      };
      if (this.taskDetails.id === 0) {
        this.taskDetails.taskDiscussions.push(comment);
        this.comment = '';
      }
      else {
        comment.taskId = this.taskDetails.id;
        addComment(comment).then((response) => {
          if (response.status === APP_CONST.RESPONSE_200) {
            this.taskDetails.taskDiscussions.push(comment);
            this.comment = '';
          }
        });
      }
    }

    compareDates(index: number) {
      if (this.formattedDate(this.taskDetails.taskDiscussions[index].createdOn.toString()) !== this.formattedDate(this.taskDetails.taskDiscussions[index - 1].createdOn.toString())) {
        return true;
      }
    }

    formattedDate(date: string) {
      return date
        ? APP_UTILITIES.formatDate(date, true, true)
        : '';
    }

    convertUTCDateToLocalDate(date: any) {
      const newDate = new Date(date);
      newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return newDate;
    }

    formattedTime(date: string) {
      const newDate = this.convertUTCDateToLocalDate( new Date(date) );
      return newDate
        ? APP_UTILITIES.formatAMPM(newDate.toString())
        : '';
    }

    getUserData(id: number, attr: string){
      const userData: any = this.ownerList.filter((el) => el.id == id);
      if (attr === 'profilePicture') {
        return userData[0][attr][0].thumbnailUrl;
      }
      else {
        return userData[0][attr];
      }
    }

}