import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import plannerStore from '@/store/modules/plannerStore';
import LocalStorage from '@/utilities/localStorageUtil';
import programListStore from '@/store/modules/programList';

@Component({})
export default class SideNav extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public getTasksStatusList: any = APP_CONST.GET_TASK_STATUS;

    collapsePanelActive:boolean = false;
    public hoverStyleObj:Object = {};
    public activeHover: any='';
    public userId:number = 0;
    public disableDownloadBtn:boolean = false;
    isMobileView:boolean=false;
    public teamRosterLabel:string = APP_CONST.TEAM_ROSTER_LABEL;
    public searchBox:string = '';

    @Prop()
    isPdfPrintInProgress!:boolean;
    
    @Prop()
    sideNavVisible!: boolean;

    @Prop()
    taskList!: any;

    @Prop()
    events!:any;

    @Prop()
    teamRosterReRenderTable!:any;

    @Prop()
    visibleTasksList!: any;

    @Prop()
    notifiedSites!:any;

    @Prop({ default: function () {
      return { id: '', maxWidth: '', minWidth: '' }; 
    } })
    resizesData!: [];

    @Prop()
    localSearch!:boolean;

    @Prop()
    isSearchRes!:boolean;

    @Prop()
    isMobileForAPiCall!:boolean;

    get isCalendarDataVisible() {
      return plannerStore.isCalendarDataVisible;
    }

    get isListViewActive() {
      return plannerStore.getIsListViewActive;
    }

    get sites(){
      return plannerStore.sitesList;
    }

    get viewType() {
      return plannerStore.getViewType;
    }

    get selectedTabEvent()  {
      return plannerStore.selectedTabEvent;
    }

    get isShowAllSitesActive() {
      return plannerStore.isShowAllSitesActive;
    }

    get toggleEllipseStatus() {
      return plannerStore.toggleEllipseStatus;
    }

    get activeTab() {
      return plannerStore.getActiveTab;
    }

    get selectedSite() {
      return plannerStore.getSiteId;
    }

    get isMobileActive() {
      return APP_UTILITIES.isMobile();
    }

    get statusList() {
      return plannerStore.getStatusList
        ? plannerStore.getStatusList
        : [];
    }

    get isProgramAdmin(){
      return plannerStore.isProgramAdmin;
    }

    get getteamRosterListData(){
      return  plannerStore.getteamRosterList;
    }

    get getRosterPayload(){
      return plannerStore.getRosterPayloadStore;
    }

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    togglePanel() {
      this.collapsePanelActive = !this.collapsePanelActive;  
      this.$emit('teamRosterReRenderTable');
      this.$emit('toggleLeftPanel', this.collapsePanelActive);
    }

    teamRosterApiCall(){
      const teamRosterSortData:any = this.persistSorting()
        ? this.persistSorting()
        : {};
      const teamRosterSearchData:any = this.persistSearching()
        ? this.persistSearching()
        : {};
      const payload = APP_CONST.TEAM_ROSTER_PAYLOAD;
      const programId = APP_UTILITIES.getCookie('programId');
      const siteId = APP_UTILITIES.getCookie('siteId');
      payload.ProgramId = Number(programId);
      payload.SiteId = Number(siteId);
      payload.Id = APP_CONST.ZERO;
      payload.Active = APP_CONST.ZERO;
      payload.SearchField = teamRosterSearchData.searchField
        ?teamRosterSearchData.searchField
        :'';
      payload.SearchValue = teamRosterSearchData.searchValues
        ?teamRosterSearchData.searchValues
        :'';
      payload.Search = '';
      payload.RoleType = APP_CONST.ZERO;
      payload.Page = APP_CONST.ONE;
      payload.Count = this.isMobileForAPiCall
        ? 10
        :APP_CONST.TWENTY_FIVE;
      payload.SortField = teamRosterSortData.sortField
        ?teamRosterSortData.sortField
        :'';
      payload.SortDir = teamRosterSortData.sortDir
        ?teamRosterSortData.sortDir
        :APP_CONST.ZERO ;
      payload.isExactMatch = (<string>teamRosterSearchData.isExactMatch)
        ? teamRosterSearchData.isExactMatch
        : false;
      plannerStore.rosterPayloadMut({...this.getRosterPayload, sortField: payload.SortField, sortDir: payload.SortDir, isExactMatch: payload.isExactMatch});
      plannerStore.teamRosterList(payload);
    }

    persistSorting(){
      const authKey = APP_UTILITIES.authTokenKeyToManage()
        ? APP_UTILITIES.authTokenKeyToManage()
        : '';
      const accountId: any = APP_UTILITIES.getCookie('accountId');
      const programId: any = APP_UTILITIES.getCookie('programId')
        ? APP_UTILITIES.getCookie('programId')
        : 0;
      const siteId:any = APP_UTILITIES.getCookie('siteId')
        ? APP_UTILITIES.getCookie('siteId')
        : 0;
      if (APP_UTILITIES.getFilteredColumns(`${authKey}-AllTeamRosterSortPayload`)) {
        let persistPayload: any = APP_UTILITIES.getFilteredColumns(`${authKey}-AllTeamRosterSortPayload`);
        persistPayload = JSON.parse(persistPayload);
        plannerStore.mutateAllSiteSortPayload(persistPayload);
        if (persistPayload.hasOwnProperty([accountId]) && persistPayload[accountId].hasOwnProperty([programId]) && persistPayload[accountId][programId].hasOwnProperty([siteId])) {
          return persistPayload[accountId][programId][siteId];
        }
        return '';
      }
      return '';
    }

    persistSearching(){
      const authKey = APP_UTILITIES.authTokenKeyToManage()
        ? APP_UTILITIES.authTokenKeyToManage()
        : '';
      const accountId: any = APP_UTILITIES.getCookie('accountId');
      const programId: any = APP_UTILITIES.getCookie('programId')
        ? APP_UTILITIES.getCookie('programId')
        : 0;
      const siteId:any = APP_UTILITIES.getCookie('siteId')
        ? APP_UTILITIES.getCookie('siteId')
        : 0;
      if (APP_UTILITIES.getFilteredColumns(`${authKey}-AllTeamRosterSearchPayload`)) {
        let persistPayload: any = APP_UTILITIES.getFilteredColumns(`${authKey}-AllTeamRosterSearchPayload`);
        persistPayload = JSON.parse(persistPayload);
        plannerStore.mutateAllSiteSearchPayload(persistPayload);
        if (persistPayload.hasOwnProperty([accountId]) && persistPayload[accountId].hasOwnProperty([programId]) && persistPayload[accountId][programId].hasOwnProperty([siteId])) {
          return persistPayload[accountId][programId][siteId];
        }
        return '';
      }
      return '';
    }

    showTasks(tab:string) {    
      plannerStore.updateSiteId(0);
      this.$emit('filterTasksByTab', tab);
      if(tab == 'events'){ 
        this.$emit('initializeTeamRoster');
        LocalStorage.saveInLocalStorage('planner_tab_active', 'events');           
        this.closePanel();
        plannerStore.updateSidePanelActivities({
          activity: 'eventTabUpdateStatus',
          value: true
        });
      }
      else if(tab == 'teamRoster'){  
        this.teamRosterApiCall();
        LocalStorage.saveInLocalStorage('planner_tab_active', 'teamRoster');
      }
      else{   
        this.$emit('initializeTeamRoster');
        LocalStorage.deleteFromLocalStorage('planner_tab_active');         
        plannerStore.updateSidePanelActivities({
          activity: 'eventTabUpdateStatus',
          value: false
        });
      }
      setTimeout(() => {
        this.$emit('updateSorting');
      }, 1000);
    }
    /**
     * Function to filter tasks by site id
     * 
     * @param id 
     */
    public selectSite(id:number) {
      plannerStore.updateSiteId(id);
      this.$emit('filterTasksBySite', id);
      this.$emit('updateNotifications',id);
      setTimeout(() => {
        this.$emit('updateSorting');
        this.$emit('notifiedSites');
      }, 1000);
    }
    
    toggleListView(viewType: string) {
    
      this.$emit('editedCompareValue',viewType );
      let isListViewActive = this.isListViewActive;
      plannerStore.updateSidePanelActivities({
        activity: 'listViewType',
        value: viewType
      });
      /* istanbul ignore else */
      if((viewType == 'list' && !isListViewActive) || (viewType == 'calendar' && isListViewActive)) {
        isListViewActive = !isListViewActive;
        plannerStore.updateSidePanelActivities({
          activity: 'isListViewActive',
          value: isListViewActive
        });
        this.$emit('toggleListView', isListViewActive);
      }    
      this.closePanel();    

    }

    /**
     * Status selection handling
     * @param order 
     */
    selectStatus(value: number) {
      if(this.statusList && this.statusList.indexOf(value) !== -1) {
        const index = this.statusList.indexOf(value);
        /* istanbul ignore else */
        if (index > -1) {
          this.statusList.splice(index, 1);
        }
      }
      else {
        this.statusList.push(value);
      }
      this.$emit('filterTasksListByStatus', this.statusList);
    }    

    get isDrilledDown() {
      const drill_available = APP_UTILITIES.getCookie('drilldown_key'); 
      return !!APP_UTILITIES.getCookie('super_user_drilldown') || !!drill_available && (JSON.parse(drill_available).actor > 0);
    }
    
    /**
     * Return task status count from task list
     * 
     * @param status 
     */
    getStatusCount(status:number){
      const { siteId } = APP_UTILITIES.coreids();
      let statusTaskList = [];
      let filteredData:any = [];
      if(this.activeTab =='myTasks') {
        statusTaskList = this.taskList.filter((item:any) => item.status==status);
        if(statusTaskList.length > 0) {
          statusTaskList.forEach((taskItem:any)=> {
            /* istanbul ignore else */
            if(taskItem.taskUsers){
              const userIdArray:number[] = [];
              const taskIdArray:number[] = [];
              taskItem.taskUsers.forEach((task:any)=> {
                /* istanbul ignore else */
                if(task.userId == this.userId && ( userIdArray && !userIdArray.includes(task.userId)) && (taskIdArray && !taskIdArray.includes(task.taskId))) {
                  filteredData.push(taskItem);
                  userIdArray.push(task.userId);
                  taskIdArray.push(task.taskId);
                }
              });
            }
          });
        }
        else {
          filteredData = [];
        }
      }
      else if(this.activeTab !='myTasks') {
        /* istanbul ignore else */
        if(this.taskList.length) {
          if(this.activeTab =='allTasks') {
            filteredData = siteId
              ? this.taskList.filter((item:any) => item.status==status && item.siteId === siteId)
              : this.taskList.filter((item:any) => item.status==status && item.siteId == APP_CONST.ZERO);
          }
          else {
            filteredData = this.selectedSite
              ? this.taskList.filter((item:any) => item.status==status && item.siteId === this.selectedSite)
              : this.taskList.filter((item:any) => item.status==status);
          }
        } 
      }
      return filteredData.length;
    }

    callHover(title:string, $event:any) {
      this.activeHover = title;
      const boundBox = $event && $event.target.getBoundingClientRect();
      const coordX = boundBox.left;
      const coordY = boundBox.top;
      this.hoverStyleObj = {
        top:(coordY + 25).toString() + 'px',
        left:(coordX + 10).toString() + 'px'
      };
    }

    isHoverActive(query:any) {
      return (query == this.activeHover);
    }

    callHoverOut() {
      this.activeHover='';
    }

    closePanel() {
      this.$emit('closeSideNav');
    }

    toggleEllipsePanel() {
      const ellipseStatus:boolean = this.toggleEllipseStatus;
      plannerStore.updateSidePanelActivities({
        activity: 'showHideSkipStatus',
        value: !ellipseStatus
      });
    }

    toggleShowAllSites() {
      const allSitesShowStatus:boolean = this.isShowAllSitesActive;
      plannerStore.updateSidePanelActivities({
        activity: 'allSitesShowStatus',
        value: !allSitesShowStatus
      });
    }

    async fetchSiteList(){
      const storedAccountId:any = APP_UTILITIES.getCookie('user_role');
      const {id} = APP_UTILITIES.coreids();
      const payload = APP_CONST.ACCOUNT_ADMIN_PROGRAMS_LIST;
      payload.id = JSON.parse(storedAccountId)[0].accountId;
      payload.userId = id;
      await programListStore.updateProgramAndSiteList(payload);
    }

    created() {
      this.isMobileView=APP_UTILITIES.isMobile();       
      this.dataIfNotMobile();
    }

    dataIfNotMobile(){
      /* istanbul ignore else */
      if(!this.isMobileActive){
        plannerStore.updateStatusList([1]);
        if(!this.isDrilledDown) {
          plannerStore.updateActiveTab('myTasks');
        }
        plannerStore.updateSidePanelActivities({
          activity: 'eventTabUpdateStatus',
          value: false
        });
        plannerStore.updateSidePanelActivities({
          activity: 'showHideSkipStatus',
          value: false
        });
        plannerStore.updateSidePanelActivities({
          activity: 'listViewType',
          value: 'list'
        });
        plannerStore.updateSidePanelActivities({
          activity: 'isListViewActive',
          value: true
        });
      }  
    }

    checkAssign(siteId:number){
      return this.notifiedSites && this.notifiedSites.find((x: any)=>(x.siteId == siteId && x.isNotify));
    }

    downloadTasks($event:any){
      this.$emit('downloadTasks');  
    }

    sortByProperty(property: any, order:string) {
      return function (a: any, b: any) {
        if(order === APP_CONST.ORDER_ASC_TEXT) {
          if (String(a[property]).toLowerCase() > String(b[property]).toLowerCase()) {
            return 1;
          }
          else if (String(a[property]).toLowerCase() < String(b[property]).toLowerCase()) {
            return -1;
          }
        }
        else {
          if (String(a[property]).toLowerCase() < String(b[property]).toLowerCase()) {
            return 1;
          }
          else if (String(a[property]).toLowerCase() > String(b[property]).toLowerCase()) {
            return -1;
          }
        }            

        return 0;
      };
    }

    mounted() {
      const { id, roleId} = APP_UTILITIES.coreids();
      this.userId = id;
      if(roleId==APP_CONST.PROGRAM_ADMIN_ROLE_ID){
        this.$emit('notifiedSites');
      }
      const event = localStorage.getItem('planner_tab_active');
      if(event && event == APP_CONST.TEAM_ROSTER_LABEL){
        this.teamRosterApiCall();
        plannerStore.getPersistenceData();
      }
    }

    hideKeyboardMobile(){
      const teamRosterSearch = this.$refs && this.$refs.teamRosterSearch as any;
      /* istanbul ignore else */
      if(teamRosterSearch){
        teamRosterSearch.blur();
      }
    }

    search(){
      this.$emit('search',this.searchBox);
      this.hideKeyboardMobile();
      this.closePanel();
    }

    clearAndcallApi(){
      if(this.searchBox == ''){
        this.$emit('clearAndcallApi',this.searchBox);
        this.hideKeyboardMobile();
        this.closePanel();
      }
    }

    clearSearch(){
      this.searchBox = '';
      this.$emit('clearSearch',this.searchBox);
      this.hideKeyboardMobile();
      this.closePanel();
    }
}

function toggleListView(arg0: string): unknown {
  throw new Error('Function not implemented.');
}
