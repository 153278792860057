import { httpClient, httpIdentityClient } from '../common/httpClient';
import API_CONST from '@/constants/ApiConst';
import { writeDataByKey, readDataByKey, clearAllData } from '@/utilities/idbUtility';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';


export function getScholarAttedanceByClassName(args: any) {
  let queryString = '';
  for (const k in args) {
    if (args !== 'isExactMatch' && args[k]) {
      if (k === 'classIds' && Array.isArray(args[k])) {
        queryString = queryString + args[k].map((v: string) => k + '=' + v + '&').join('');
      }
      else {
        queryString = queryString + k + '=' + args[k] + '&';
      }
    }
    else {
      queryString = queryString + k + '=' + args[k] + '&';
    }
  }
  queryString = queryString.slice(0, -1);


  return httpClient().get(`${API_CONST.GET_SCHOLAR_ATTEDANCE}?${queryString}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function undoScholarAttadance(obj: any) {
  return httpClient().post(`${API_CONST.REVERT_SCHOLAR_ATTEDANCE}`, obj).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function markScholarAttadance(obj: any) {
  return httpClient().post(`${API_CONST.MARK_SCHOLAR_ATTEDANCE}`, obj).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}
export function getStaffByClassId(classId: number) {

  return httpIdentityClient().get(`${API_CONST.GET_STAFF_BY_ID}/${classId}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}
export function getClassRoomService(obj: any) {
  if (APP_UTILITIES.checkBrowserStatus()) {
    return getAllClassRoom(obj);
  }
  else{
    return readDataByKey(APP_CONST.GET_CLASS_ROOM, obj).then((response: any) => {
      return { data: response, status: APP_CONST.RESPONSE_200, statusText: 'OK' };
    }).catch(error => {
      return { data: error.data, status: APP_CONST.RESPONSE_400, statusText: error.statusText };
    });
  }
}

function getAllClassRoom(obj : any){
  let url = `${API_CONST.POST_SITE_DATA}/${obj.siteId}/program/${obj.programId}/${API_CONST.CLASSROOMS}`;
  if (obj.hasOwnProperty('includeAll') && obj.includeAll) {
    url = `${url}?includeAll=${obj.includeAll}`;
  }
  return httpClient().get(url).then((response: any) => {
    storeClassRoomIndxDB(response.data);
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

function storeClassRoomIndxDB(data: any) {
  clearAllData(APP_CONST.GET_CLASS_ROOM).then(function () {
    writeDataByKey(APP_CONST.GET_CLASS_ROOM,APP_CONST.GET_CLASS_ROOM, data).then((newAccRes: any) => {
    });
  });
}

export function getAllHolidaysService(siteId: number, check: boolean,programId:any) {
  return httpClient().get(`${API_CONST.GET_ALL_HOLIDAYS}?programId=${programId}&siteId=${siteId}&isEvent=${check}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function createHolidays(payload: any) {
  return httpClient().post(`${API_CONST.GET_ALL_HOLIDAYS}`, payload).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    const res = error;
    return { data: (res.data.Message || res.data.value || res.data), status: error.status, statusText: error.statusText };
  });
}

export function editHolidays(payload: any,id:number) {
  return httpClient().put(`${API_CONST.GET_ALL_HOLIDAYS}/${id}`, payload).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    const res = error;
    return { data: (res.data.Message || res.data.value || res.data), status: error.status, statusText: error.statusText };
  });
}

export function downloadAttendancePDF(payload:any) {
  if(payload.tabValue == 'Week'){
    return httpClient().get(`${API_CONST.ATTENDANCE_DOWNLOAD}?ClassroomName=${payload.ClassRoomName}&StaffName=${payload.StaffName}&${payload.Date}&SiteId=${payload.SiteId}&${payload.ColumnName}&Type=${payload.Type}&ProgramId=${payload.ProgramId}&StartDate=${payload.StartDate}&EndDate=${payload.EndDate}&Group=${payload.Group}&IsReport=${payload.IsReport}&sortDir=${payload.sortDir}&SortField=${payload.SortField}&weekDay=${payload.weekDay}&classid=${payload.classid}`, {responseType: 'arraybuffer'}).then((response: any) => {
      return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch((error: any) => {
      return { data: error.data, status: error.status, statusText: error.statusText };
    });
  }
  else{
    return httpClient().get(`${API_CONST.ATTENDANCE_DOWNLOAD}?ClassroomName=${payload.ClassRoomName}&StaffName=${payload.StaffName}&${payload.ColumnName}&SiteId=${payload.SiteId}&Type=${payload.Type}&ProgramId=${payload.ProgramId}&StartDate=${payload.StartDate}&EndDate=${payload.EndDate}&Group=${payload.Group}&IsReport=${payload.IsReport}&sortDir=${payload.sortDir}&SortField=${payload.SortField}&classid=${payload.classid}`, {responseType: 'arraybuffer'}).then((response: any) => {
      return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch((error: any) => {
      return { data: error.data, status: error.status, statusText: error.statusText };
    });
  }
}

export function resetData(payload: any) {
  return httpClient().post(`${API_CONST.ATTENDANCE_RESET}`, payload).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    const res = error;
    return { data: (res.data.Message || res.data.value || res.data), status: error.status, statusText: error.statusText };
  });
}
