import { Component, Prop, Vue } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import DisplayColumnFilter from '../displayColumnFilter/DisplayColumnFilter.vue';


@Component({
  components: {
    'display-columns-dropdown': DisplayColumnFilter
  }
})
export default class MovableTableComponent extends Vue {
  public showSettings: boolean = false;
  public dynamicHeight: string = '';
  public dynamicWidth: string = '';
  public elementScroller: any = document.getElementById('table-scroll')
    ? document.getElementById('table-scroll')
    : { style: { width: '' } };
  public element: any = document.getElementById('tableResizableOne')
    ? document.getElementById('tableResizableOne')
    : { offsetWidth: '' };
  public leftTableEle: any = document.getElementById('smart-fixed-table');
  public scrollTimeout: any = {};
  public headerLength: any = 0;
  public drill: boolean = false;
  public mainContainerWidth = APP_CONST.ZERO;
  public tableWidth = APP_CONST.ZERO;
  public hoverText: string = '';
  public hoverId: number = 0;
  public hoverStyleObj: any = {};
  public hoverIndex: number = -1;
  public isTooltip: boolean = false;

  @Prop({ default: false })
    showEditIcon!: boolean;

  @Prop()
  tableData!: [];

  @Prop()
  headers!: [];

  @Prop()
  isSearching!: boolean;

  @Prop({ default: false })
  isResizer!: boolean;

  @Prop()
  displayColumn!: any;

  @Prop()
  componentName!: string;

  @Prop({ default: false })
  isSortingSearching!: boolean;

  @Prop()
  payload!: any;

  @Prop({ default: true })
  showCheckBox!: boolean;

  @Prop({ default: function () {
    return { id: '', maxWidth: '', minWidth: '' };
  }})
  resizesData!: [];

  @Prop({ default: function () {
    return [];
  }})
  searchLists!: { header: string; values: any[] }[];

  @Prop({ default: 'blue' })
  theme!: string;

  @Prop()
  togglePanelActive!: boolean;

  @Prop()
  movableTableHeaders!: any;

  @Prop()
  mobileFixedTableHeaders!: any;

  @Prop()
  isMobileForAPiCall!: boolean;


  /* istanbul ignore next */
  getColumnWidth(width: string) {
    const { roleId } = APP_UTILITIES.coreids();
    const safeMargin = 40;

    if ((roleId > 2 || roleId == 0) && this.$route.path == '/account/programs') {
      width = width.slice(0, -2);
      return Number(width) + (this.showCheckBox
        ? 11.5
        : 15) + 'px';
    }

    else if (this.componentName == 'team-roster') {
      const box: any = <HTMLDivElement>document.getElementById('smart-table-wrapper');
      let widthfixed: any = '';
      if (box && box.firstChild.offsetWidth) {
        widthfixed = box.firstChild.offsetWidth;
      }

      const movable: any = <HTMLDivElement>document.getElementById('tableResizableOne');
      let width2: any = '';
      if (movable && movable.offsetWidth) {
        width2 = movable.offsetWidth;
      }
      const fixedHeaders: any = APP_CONST.TEAM_ROSTER_HEADER.FIXED;
      let totalFixedWidth: number = 0;
      let totalMovableWidth: number = 0;
      fixedHeaders.forEach((element: any) => {
        totalFixedWidth += parseInt(element.width);
      });
      const resizeCols: any = this.resizesData;
      let noOfresizableColumns: number = this.headerLength;
      this.displayColumn.forEach((element: any) => {
        if (element.display === true) {
          const resizeElement = resizeCols.filter((item: any) => element.value == item.id);
          if (resizeElement.length > 0) {
            totalMovableWidth += parseInt(resizeElement[0].maxWidth);
            noOfresizableColumns--;
          }
          else {
            totalMovableWidth += parseInt(element.width);
          }
        }

      });
      let spaceToAdjust: number = 0;
      if (widthfixed >= (totalFixedWidth + totalMovableWidth)) {
        spaceToAdjust = widthfixed - (totalFixedWidth + totalMovableWidth) - safeMargin;
        const widthDivide:number = spaceToAdjust / noOfresizableColumns;
        width = width.slice(0, -2);
        return Number(width) + widthDivide + 'px';
      }
      else {
        spaceToAdjust = (totalFixedWidth + totalMovableWidth) - widthfixed + safeMargin;
        const widthDivide:number = Math.floor(spaceToAdjust / noOfresizableColumns);
        width = width.slice(0, -2);
        const colWidth: number = Number(width) - widthDivide;

        return colWidth >= 180
          ? Math.floor(colWidth) + 'px'
          :'190px';
      }
    }
    else{
      return width;
    }

  }

  /* istanbul ignore next */
  get tableHeaders() {
    const headers: any = [];
    if (this.isMobileForAPiCall) {
      this.mobileFixedTableHeaders && this.mobileFixedTableHeaders.length && this.mobileFixedTableHeaders.forEach((head: any) => {
        headers.push(head);
      });
      this.movableTableHeaders && this.movableTableHeaders.length && this.movableTableHeaders.forEach((head: any) => {
        this.displayColumn && this.displayColumn.length && this.displayColumn.forEach((filteredHead: any) => {
          if ((head.filterKey === filteredHead.filterKey) && filteredHead.display) {
            headers.push(head);
          }
        });
      });
    }
    else {
      this.headers && this.headers.length && this.headers.forEach((head: any) => {
        this.displayColumn && this.displayColumn.length && this.displayColumn.forEach((filteredHead: any) => {
          if ((head.filterKey === filteredHead.filterKey) && filteredHead.display) {

            if (filteredHead.groupedColumns && filteredHead.groupedColumns.length) {
              filteredHead.groupedColumns.forEach((childHead: any) => {
                headers.push(childHead);
              });
            }
            else {
              headers.push(head);
            }

          }
        });
      });
    }
    this.headerLength = headers.length;
    this.scrollAndHeight();
    const tableCells: any = document.getElementsByClassName('table-cell');
    for (const cell of tableCells) {
      if (cell && cell.style && cell.style.maxWidth && cell.style.minWidth) {
        cell.style.maxWidth = '';
        cell.style.minWidth = '';
        cell.style.width = '';
      }
    }
    for (const cell of tableCells) {
      headers && headers.length && headers.forEach((item: any) => {
        if (item.value == cell.id) {
          cell.style.width = item.width;
        }
      });
    }
    const map = new Map();
    this.resizesData && this.resizesData.length && this.resizesData.forEach((col: { id: string; maxWidth: string; minWidth: string }) => {
      map.set(col.id,col.minWidth);
    });
    headers.length && headers.forEach((item: any) => {
      if (map.has(item.value)) {
        item.width = map.get(item.value);
      }
    });

    return headers;
  }

  /* istanbul ignore next */
  get filterTableData() {
    const tableData = this.tableData;
    this.scrollAndHeight();
    return tableData;
  }

  /* istanbul ignore next */
  get decidedWidth(){
    if (this.componentName == 'programs-table') {
      return this.dynamicWidth;
    }
    else {
      'initial';
    }
  }

  /* istanbul ignore next */
  get showScrollbar() {
    if (this.tableHeaders.length) {
      if (this.componentName == 'programs-table' && this.tableWidth < this.mainContainerWidth) {
        return false;
      }
      return true;
    }
    else {
      return false;
    }
  }


  get sortIcon() {
    if (this.payload.sortField == this.payload.searchField || this.payload.sortField == this.payload.SearchField) {
      return this.payload.sortDir == APP_CONST.ONE
        ? 'sort-a-to-z-white'
        : 'sort-z-to-a-white';
    }
    else {
      return this.payload.sortDir == APP_CONST.ONE
        ? `sort-a-to-z-${this.theme}`
        : `sort-z-to-a-${this.theme}`;
    }
  }

  updated() {
    const movableTableHeader = <HTMLElement>document.getElementById('movable-table-header');
    const movableTableData = <HTMLElement>document.getElementById('scrollableArea');
    setTimeout(() => {
      movableTableData.scrollLeft = movableTableHeader && movableTableHeader.scrollLeft;
    }, 0);
  }

  /* istanbul ignore next */
  mounted() {
    if (APP_UTILITIES.getCookie(APP_CONST.SUPER_USER_DRILLDOWN)) {
      const drilldrown: any = APP_UTILITIES.getCookie(APP_CONST.SUPER_USER_DRILLDOWN);
      this.drill = JSON.parse(JSON.stringify(drilldrown));
    }

    this.enableResizer();
    window.addEventListener('scroll', this.callHoverOut, false);
    if (this.isSortingSearching) {
      if (this.isMobileForAPiCall) {
        if (document.getElementById('scrolling-table')) {
          const element: any = document.getElementById('scrolling-table');
          element.addEventListener('mousewheel', this.closePanelMobile, false);
        }
      }
      else {
        window.addEventListener('scroll', this.closePanel, false);
        if (document.getElementById('scrollableArea')) {
          const element: any = document.getElementById('scrollableArea');
          element.addEventListener('scroll', this.closePanel, false);
        }
      }
    }
    const header = this.headers.filter((el: any) => {
      return el.isOpen;
    });
    header.length && this.openSortingDropdown(header[0], {});
  }

  enableResizer() {
    /* istanbul ignore else */
    if (this.isResizer && !this.isMobileForAPiCall) {
      window.addEventListener('mousewheel', this.onMouseWheel, false);
      window.addEventListener('touchmove', this.onMouseWheel, false);
      this.createResizableTable(document.getElementById('tableResizableOne'));
    }
  }

  closePanelMobile(e: any) {
    /* istanbul ignore else */
    if (e.deltaX != '-0') {
      this.$emit('closePanel');
    }
  }

  /* istanbul ignore next */
  onMouseWheel(e: any) {
    const scrollerView: any = document.getElementById('layerWhenScrolling');
    let d = ((typeof e.wheelDelta != 'undefined')
      ? (-e.wheelDelta)
      : e.detail);
    d = 100 * ((d > 0)
      ? 1
      : -1);
    if (scrollerView && scrollerView.classList) {
      scrollerView.classList.add('stackingElement');
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(function () {
        scrollerView.classList.remove('stackingElement');
      }, 800);
    }
  }

  createResizableTable(table: any) {
    const cols = table && table.querySelectorAll('.table-cell');
    cols && cols.forEach((col: any) => {
      // Add a resizer element to the column
      const column = document && document.querySelectorAll(`.${col.id}`);
      const resizer = document.createElement('div');
      resizer.classList.add(`resizer-${this.theme}`);

      // Set the height
      resizer.style.height = `${table.offsetHeight}px`;

      col.appendChild(resizer);

      this.createResizableColumn(col, resizer);

      this.resizesData
        && this.resizesData.length
        && this.resizesData.forEach((item: { id: string; maxWidth: string; minWidth: string }) => {
          if (item.id == col.id) {
            if (this.componentName !== 'programs-table') {
              col.style.maxWidth = item.maxWidth;
            }
            else {
              col.style.width = item.minWidth;
            }
            col.style.minWidth = item.minWidth;
          }
        });
      column && column.forEach((colm: any) => {
        this.resizesData
          && this.resizesData.length
          && this.resizesData.forEach((item: { id: string; maxWidth: string; minWidth: string }) => {
            if (this.componentName == 'team-roster') {
              if (`table-cell ${item.id} roster-${item.id}` == colm.className) {
                colm.style.maxWidth = item.maxWidth;
                colm.style.minWidth = item.minWidth;
              }
            }
            else {
              if (`table-cell ${item.id}` == colm.className) {
                if (this.componentName !== 'programs-table') {
                  colm.style.maxWidth = item.maxWidth;
                }
                else {
                  colm.style.width = item.minWidth;
                }
                colm.style.minWidth = item.minWidth;
              }
            }
          });
      });
    });
  }

  createResizableColumn(col: any, resizer: any) {
    let x = 0;
    let w = 0;

    const mouseDownHandler = (e: any) => {
      x = e.clientX;

      const styles = window.getComputedStyle(col);
      if (styles && styles.width) {
        w = parseInt(styles.width, 10);
      }

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);

      resizer.classList.add(`resizing-${this.theme}`);
    };

    const mouseMoveHandler = (e: any) => {
      const column: any = document.querySelectorAll(`.${col.id}`);
      const dx = e.clientX - x;
      const width = (w + dx) <= 80
        ? 80
        : (w + dx);
      this.$emit('presistResize', { column: col, maxWidth: `${width}px`, minWidth: `${width}px` });
      if (this.componentName == 'programs-table') {
        col.style.width = `${width}px`;
      }
      else {
        col.style.maxWidth = `${width}px`;
      }
      col.style.minWidth = `${width}px`;
      column && column.forEach((el: any) => {
        el.style.minWidth = `${width}px`;
        if (this.componentName == 'programs-table') {
          el.style.width = `${width}px`;
        }
        else {
          el.style.maxWidth = `${width}px`;
        }
      });
      this.setWidth();
    };

    const mouseUpHandler = () => {
      resizer.classList.remove(`resizing-${this.theme}`);
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
      this.updateScrollBar();
      this.elementScroller.style.width = this.element.offsetWidth + 'px';
      this.setWidth();
    };

    resizer.addEventListener('mousedown', mouseDownHandler);


  }


  scrollAndHeight() {
    this.setHeight();
    setTimeout(() => {
      const table = document.getElementById('tableResizableOne');
      if (table) {
        this.createResizableTable(table);
      }
      this.elementScroller = document.getElementById('table-scroll')
        ? document.getElementById('table-scroll')
        : { style: { width: '' } };
      this.element = document.getElementById('tableResizableOne')
        ? document.getElementById('tableResizableOne')
        : { offsetWidth: '' };
      this.leftTableEle = document.getElementById('smart-fixed-table');
      this.updateScrollBar();
      this.setWidth();
    }, 50);
  }

  updateScrollBar() {
    this.elementScroller.style.width = this.element.offsetWidth + 'px';
    const columnAvaiable = this.displayColumn && this.displayColumn.length && this.displayColumn.some((column: any) => {
      if (column.display) {
        return true;
      }
    });
    this.elementScroller.style.width = columnAvaiable
      ? this.elementScroller.style.width
      : '0';
  }

  getStatus(status: any) {
    return status == 1
      ? 'Active'
      : 'Inactive';
  }

  setHeight() {
    setTimeout(() => {
      const leftTableEle = document.getElementById('smart-fixed-table');
      const tableHeight = leftTableEle && leftTableEle.offsetHeight;
      this.dynamicHeight = tableHeight && tableHeight.toString() + 'px' || '';
    }, 0);
  }

  setWidth() {
    setTimeout(() => {
      const leftTableEle = document.getElementById('tableResizableOne');
      const mainContainer = document.getElementById('smart-table-wrapper');
      const mainContainerWidth: any = mainContainer && mainContainer.offsetWidth - APP_CONST.FIVE_HUNDRED_FIFTY;
      const tableWidth: any = leftTableEle && leftTableEle.offsetWidth;
      this.mainContainerWidth = mainContainerWidth;
      this.tableWidth = tableWidth;
      const scrollBar = document.getElementById('scrollableArea');
      if (tableWidth < mainContainerWidth) {
        if (scrollBar && this.componentName == 'programs-table') {
          scrollBar.style.overflowX = 'hidden';
        }
        if (tableWidth) {
          this.dynamicWidth = tableWidth && tableWidth.toString() + 'px' || '';
        }
        else {
          this.dynamicWidth = 0 + 'px' || '';
        }
      }
      else {
        if (scrollBar && this.componentName == 'programs-table') {
          scrollBar.style.overflowX = 'auto';
        }
        if (tableWidth) {
          this.dynamicWidth = '100%';
        }
      }
    }, APP_CONST.FIFTY);
  }


  openSortingDropdown(header: any, event: any) {
    /* istanbul ignore else */
    if (this.isSortingSearching) {
      const elem = <HTMLDivElement>document.getElementById(header.value);
      const pos = elem && elem.getBoundingClientRect();
      let x = pos && pos.left + APP_CONST.FOURTEEN;
      const y = pos && pos.top + APP_CONST.FOURTY_FIVE;
      const xPosPercent = (x / window.innerWidth) * APP_CONST.HUNDRED;
      x = x - (xPosPercent >= APP_CONST.SEVENTY_FIVE
        ? APP_CONST.HUNDRED
        : APP_CONST.ZERO);
      this.$emit('openSortingDropdown', header, { x, y });
    }
  }

  navigateToPage(accountData: any) {
    this.$emit('navigateToPage', accountData);
  }

  handleSortingArrows(header: any) {
    this.tableHeaders.forEach((originalHeader: any) => {
      if (originalHeader.value === header.value) {
        originalHeader.isOpen = !originalHeader.isOpen;
      }
    });
  }

  decideSortingDirection(field: string) {
    let sortDirection = APP_CONST.ONE;
    /* istanbul ignore else */
    if (this.payload.sortField === field) {
      sortDirection = this.payload.sortDir === APP_CONST.ONE
        ? APP_CONST.TWO
        : APP_CONST.ONE;
    }
    this.$emit('sortScholars', { sortField: field, sortDirection: sortDirection });
  }

  closePanel() {
    this.$emit('closePanel');
  }

  /* istanbul ignore next */
  beforeDestroy() {
    if (this.isResizer) {
      window.removeEventListener('mousewheel', this.onMouseWheel, false);
      window.removeEventListener('touchmove', this.onMouseWheel, false);
    }
    if (this.isSortingSearching) {
      window.removeEventListener('scroll', this.closePanel, false);
      if (document.getElementById('scrollableArea')) {
        const elemen: any = document.getElementById('scrollableArea');
        elemen.removeEventListener('scroll', this.closePanel, false);
      }
    }
  }

  filterColumns(displayColumn: any) {
    this.$emit('filterColumns', displayColumn);
    this.$emit('calculateTableWidth');
  }

  filterDisplayColumn(column: any) {
    this.$emit('filterDisplayColumn', column);
  }

  openSortingDropdownFixedMobile(header: any, $event: any) {
    if (this.isSortingSearching && header.action) {
      const elem = <HTMLDivElement>document.getElementById(header.value);
      const pos = elem && elem.getBoundingClientRect();
      let x = 0;
      const deviceWidth = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth;
      if (pos && pos.left < (deviceWidth-255) && (pos.left > 50)) {
        x = pos.left;
      }
      else if (pos && pos.left < 51) {
        x = 53;
      }
      else {
        x = deviceWidth-275;
      }
      const y = pos && pos.top + APP_CONST.FOURTY_FIVE;
      this.$emit('openSortingDropdown', header, { x, y });
    }
  }

  callHover(index: number, value: string, event: any, id?: number,) {
    this.hoverIndex = index;
    this.$emit('rowHovered', index);
    const target = event.target;
    const parent = target.parentElement;
    const hoverTextLength = value && `${value}`.trim().length;

    if (hoverTextLength && (target.tagName !== APP_CONST.STRONG_UPPERCASE
      ? target.offsetHeight > 32
      : parent.offsetHeight > 32)) {
      this.isTooltip = true;
      this.hoverText = value;
      const boundBox = event && event.target && event.target.getBoundingClientRect();
      const coordX = boundBox && boundBox.left;
      const coordY = boundBox && boundBox.top;
      this.hoverStyleObj = {
        position: 'fixed',
        top: (coordY + 30).toString() + 'px',
        left: (coordX + 30).toString() + 'px'
      };
    }
    event.stopImmediatePropagation();
  }

  callHoverOut() {
    this.hoverText = '';
    this.hoverId = 0;
    this.isTooltip = false;
    this.hoverIndex = -1;
    this.$emit('rowHovered', -1);
  }
}